import { createStore } from "vuex";

import axios from "axios";
import config from "../api/config.json";

const store = createStore({
  state: {
    //* Company Info *//
    companySiteIcon: "", // Done
    companyLogo: "", // Done
    websiteDomain: "", // No Need？
    siteName: "", // Done
    copyright: "",
    companyCertificateProof: "", // Not Done
    companyAddress: "", // Done
    facebook: "",
    line: "",
    email: "",

    //* Data *//
    countryList: [], // Done
    stateList: [], // Done
    carBrandList: [],

    //* User Info *//
    user: "",
    username: "",
    phone: "",
    // passport: "",
    token: "",
  },
  mutations: {
    //* Company Info *//
    setCompanyName(state, companyName) {
      state.companyName = companyName || "";
    },
    setCompanyProfileImage(state, companyLogo) {
      state.companyLogo = companyLogo || "";
    },
    setCompanySiteIcon(state, companySiteIcon) {
      state.companySiteIcon = companySiteIcon || "";
    },
    setWebsiteDomain(state, websiteDomain) {
      state.websiteDomain = websiteDomain || "";
    },
    setSiteName(state, siteName) {
      state.siteName = siteName || "";
    },
    setCopyright(state, copyright) {
      state.copyright = copyright || "";
    },
    setCompanyCertificateProof(state, companyCertificateProof) {
      state.companyCertificateProof = companyCertificateProof || "";
    },
    setCompanyAddress(state, companyAddress) {
      state.companyAddress = companyAddress || "";
    },
    setFacebook(state, facebook) {
      state.facebook = facebook || "";
    },
    setLine(state, line) {
      state.line = line || "";
    },
    setEmail(state, email) {
      state.email = email || "";
    },

    //* Data *//
    setCountryList(state, countryList) {
      state.countryList = countryList || [];
    },
    setStateList(state, stateList) {
      state.stateList = stateList || [];
    },
    setCarBrandList(state, carBrandList) {
      state.carBrandList = carBrandList || [];
    },

    //* User Info *//
    setUser(state, payload) {
      state.user = payload.id || "";
      state.username = payload.username || "";
      state.phone = payload.phone || "";
      // state.passport = passport || "";
      state.token = payload.token || "";
      localStorage.setItem("token", payload.token);
    },
    // setUser(state, user) {
    //   state.user = user || "";
    // },
    // setUsername(state, username) {
    //   state.username = username || "";
    // },
    // setPhone(state, phone) {
    //   state.phone = phone || "";
    // },
    // setPassport(state, passport) {
    //   state.passport = passport || "";
    // },
    // setToken(state, token) {
    //   state.token = token || "";
    // },
  },
  actions: {
    async fetchCompanyData({ commit }) {
      try {
        const companyResponse = await axios.get(
          `${config.apiBaseUrl}/${config.version}/system/site`
        );

        if (companyResponse.status <= 200 && companyResponse.status > 300) {
          throw new Error("Failed to fetch company data.");
        }

        let companyData = companyResponse.data;
        commit("setCompanySiteIcon", companyData.site_ico || "");
        commit("setCompanyProfileImage", companyData.logo_image || "");
        commit("setWebsiteDomain", companyData.websiteDomain || "");
        commit("setSiteName", companyData.site_name || "");
        commit("setCopyright", companyData.site_copyright);
        commit(
          "setCompanyCertificateProof",
          companyData.companyCertificateProof || ""
        );
        commit("setCompanyAddress", companyData.site_company_address || "");
        commit("setFacebook", companyData.site_facebook || "");
        commit("setLine", companyData.site_line || "");
        commit("setEmail", companyData.site_email || "");
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    },
    /**
     * 外国国家
     * pid: 1
     */
    async fetchCountryData({ commit }) {
      try {
        const countryResponse = await axios.get(
          `${config.apiBaseUrl}/${config.version}/category/detail`,
          { params: { pid: 1 } }
        );

        if (countryResponse.status <= 200 && countryResponse.status > 300) {
          throw new Error("Failed to fetch country data.");
        }
        commit("setCountryList", countryResponse.data.data || []);
        // console.log("countryResponse", countryResponse.data.data);
      } catch (error) {
        // 发生错误
        console.error("Error fetching country data:", error);
      }
    },
    /**
     * 国内
     * pid: 2
     */
    async fetchStateData({ commit }) {
      try {
        // 外国国家
        // pid: 1
        const stateResponse = await axios.get(
          `${config.apiBaseUrl}/${config.version}/category/detail`,
          { params: { pid: 2 } }
        );

        if (stateResponse.status <= 200 && stateResponse.status > 300) {
          throw new Error("Failed to fetch state data.");
        }
        commit("setStateList", stateResponse.data.data || []);
        // console.log("stateResponse", stateResponse.data.data);
      } catch (error) {
        // 发生错误
        console.error("Error fetching state data:", error);
      }
    },
    /**
     * 外国国家
     * pid: 7
     */
    async fetchCarBrandData({ commit }) {
      try {
        const countryResponse = await axios.get(
          `${config.apiBaseUrl}/${config.version}/category`,
          { params: { pid: 7 } }
        );

        if (countryResponse.status <= 200 && countryResponse.status > 300) {
          throw new Error("Failed to fetch car brand data.");
        }
        commit("setCarBrandList", countryResponse.data.data || []);
        // console.log("countryResponse", countryResponse.data.data);
      } catch (error) {
        // 发生错误
        console.error("Error fetching car brand data:", error);
      }
    },
    /**
     * 账号登录
     */
    login({ commit }, { id, username, phone, token }) {
      commit("setUser", { id, username, phone, token });
    },
    /**
     * 账号登出
     */
    logout({ commit }) {
      commit("setUser", "", "", "", "");
      localStorage.removeItem("token");
    },
  },
  getters: {
    //* Company Info *//
    getCompanySiteIcon: (state) => state.companySiteIcon,
    getCompanyProfileImage: (state) => state.companyLogo,
    getWebsiteDomain: (state) => state.websiteDomain,
    getSiteName: (state) => state.siteName,
    getCopyright: (state) => state.copyright,
    getCompanyCertificateProof: (state) => state.companyCertificateProof,
    getCompanyAddress: (state) => state.companyAddress,
    getCompanyFacebookLink: (state) => state.facebook,
    getCompanyLineId: (state) => state.line,
    getCompanyEmail: (state) => state.email,
    //* Data *//
    getCountryList: (state) => state.countryList,
    getStateList: (state) => state.stateList,
    getCarBrandList: (state) => state.carBrandList,
    //* User Info *//
    getUser: (state) => state.user,
    getUsername: (state) => state.username,
    getPhone: (state) => state.phone,
    getPassport: (state) => state.passport,
    getToken: (state) => state.token,
  },
});

export default store;
