<template>
  <div class="footer">
    <!-- Banner -->
    <div class="banner"></div>
    <!-- List -->
    <div class="container">
      <div class="menu-list">
        <!-- Customer Service -->
        <div class="section">
          <h4>{{ $t("customerService") }}</h4>
          <ul
            class="nav-list"
            style="
              display: flex;
              flex-direction: column;
              position: relative;
              width: auto;
              height: auto;
              padding-left: 10px;
            "
          >
            <!-- <router-link to="/article">
              <li @click="scrollToTop()">
                {{ $t("travelArticle") }}
              </li></router-link
            > -->
            <router-link to="/about_us"
              ><li @click="scrollToTop()">{{ $t("aboutUs") }}</li></router-link
            >
            <router-link to="/contact_us"
              ><li @click="scrollToTop()">
                {{ $t("contactUs") }}
              </li></router-link
            >
            <router-link to="/past_work"
              ><li @click="scrollToTop()">{{ $t("pastWork") }}</li></router-link
            >
          </ul>
        </div>
        <!-- Popular tours -->
        <div class="section">
          <h4>{{ $t("popularTour") }}</h4>
          <ul
            style="
              display: flex;
              flex-direction: column;
              position: relative;
              width: auto;
              height: auto;
              padding-left: 10px;
            "
          >
            <li
              v-for="country in popularCountry"
              :key="country.title"
              @click="navigateToInternational(country.tag, country.code)"
            >
              {{ country.title }} {{ $t("tour") }}
            </li>
          </ul>
        </div>
        <!-- {{ $t('contactUs') }} -->
        <div class="info-section">
          <h4>{{ $t("contactUs") }}</h4>
          <ul>
            <li>
              <i class="fa-solid fa-phone"></i>
              <a href="tel:09-8570-8809">09-8570-8809</a>
            </li>
            <li>
              <i class="fa-solid fa-phone"></i>
              <a href="tel:09-8845-4210">09-8845-4210</a>
            </li>
            <li>
              <i class="fa-solid fa-envelope"></i>
              <a :href="`mailto:${email}`">{{ email }}</a>
              <!-- <a :href="`mailto:${email}`">{{ companyEmail[0] }}</a> -->
            </li>
            <li>
              <i class="fa-regular fa-clock"></i>
              <a>{{ $t("mondayToFriday") }}</a>
              <!-- <a>{{ businessHour }}</a> -->
            </li>
          </ul>
        </div>
        <!-- Address -->
        <div class="info-section">
          <!-- <h4>{{ $t("companyName") }}</h4> -->
          <h4>{{ companyName }}</h4>
          <ul>
            <li>
              <a style="width: 100%">{{ companyAddress }}</a>
              <!-- <a style="width: 100%">{{ $t("companyAddress") }}</a> -->
            </li>
            <li style="margin-top: 5%" class="travel-license">
              <a>Travel License : 11/03994</a>
              <!-- <a>Travel License : {{ travelLicense }}</a> -->
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom-footer">
    <div class="bottom-container">
      <div></div>
      <div class="copyright">
        <!-- <a>Copyright © 2024 https://www.sunmoon.co.th only.</a> -->
        <a>{{ copyrightText }}</a>
      </div>
      <div class="back-to-top">
        <a @click="scrollToTop()">{{ $t("backToTop") }}</a>
        <div class="arrow-top" @click="scrollToTop()">
          <i class="fa-solid fa-arrow-up"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const router = useRouter();
const store = useStore();

const companyName = computed(() => store.getters.getSiteName);
const companyAddress = computed(() => store.getters.getCompanyAddress);

const email = computed(() => {
  return store.getters.getCompanyEmail;
  // return "special.sunmoon@gmail.com";
});

/**
 *  从store获取国家列表
 */
const countryArray = computed(() => {
  return store.getters.getCountryList;
});

/**
 *  从store获取版权字眼
 */
const copyrightText = computed(() => {
  return store.getters.getCopyright;
});

/**
 * 随机选择国家显示在Footer
 */
const popularCountry = computed(() => {
  let tempArray = [];

  if (countryArray.value.length > 4) {
    for (let i = 0 - 1; i < 3; i++) {
      const j = Math.floor(Math.random() * countryArray.value.length);
      const selectedCountry = countryArray.value[j];
      if (
        !tempArray.find((item) => item && item.title === selectedCountry.title)
      ) {
        tempArray.push(selectedCountry);
      } else {
        i--;
      }
    }
  } else {
    countryArray.value.forEach((country) => tempArray.push(country));
  }
  return tempArray;
});

/**
 * 转移至 外国旅游 界面
 * @param tag 外国旅游 标签
 */
const navigateToInternational = (countryId) => {
  // 设置请求
  let queryParams = {};

  if (countryId && countryId != "") {
    queryParams.category = countryId || 1;
  }

  router.push({
    path: "/tour_list/search",
    query: queryParams,
  });
  window.scrollTo(0, 0);
};

/**
 * 回到页面上方
 */
const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};
</script>
<style scoped>
.footer {
  background: #f8f7f3;
}

.banner {
  background: url("../assets/footer-banner.png") no-repeat center 33px #f8f7f3;
  padding-top: 192px;
  background-size: 100%;

  @media (max-width: 991px) {
    background: url("../assets/footer-banner.png") left 33px #f8f7f3;
  }
}

.container {
  position: relative;
  width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding: 80px 0 40px 0;

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 100%;
  }

  @media (max-width: 991px) {
    padding-top: 50px;
    width: auto;
  }
}

h4 {
  color: #000166;
  margin-bottom: 10px;
}

/* Option List */
.menu-list {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  @media (max-width: 991px) {
    padding-left: 15px;
    flex-direction: column;
    justify-content: center;
  }
}

.section {
  display: flex;
  flex-direction: column;
  width: fit-content;
  padding: 0 10px 20px 0;
}

.section ul {
  display: flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  margin: 0;
  padding-left: 10px;
}

.section li {
  margin-left: 10px;
  line-height: 2em;
  font-size: 1.0833em;
  color: #808182;
}

.section li:hover {
  cursor: pointer;
  color: #12a0c3;
}

.nav-list::v-deep a {
  color: #808182;
  text-decoration: none;
}

/* Info List */
.info-section {
  padding-bottom: 20px;
  max-width: 25%;

  @media (max-width: 991px) {
    width: auto;
    max-width: 100%;
    padding-right: 20px;
  }
}

.info-section ul {
  display: flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  margin: 0;
  padding-left: 10px;
}

.info-section li {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -3%;
  line-height: 1.42857143;
  font-size: 1.0833em;

  @media (max-width: 991px) {
    margin-left: 0;
    line-height: 30px;
  }
}

.info-section li i {
  color: #000166;
  font-size: 1.2em;
  padding-right: 10px;
}

.info-section li a {
  text-decoration: none;
  color: #808182;

  @media (max-width: 991px) {
    font-size: 0.8em;
  }
}

.travel-license a {
  @media (max-width: 991px) {
    margin-top: 10px;
  }
}

/* Bottom Footer */
.bottom-footer {
  width: 100%;
  background: white;
}

.bottom-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 1170px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 100%;
  }

  @media (max-width: 991px) {
    width: auto;
    display: flex;
    flex-direction: column;
  }
}

.bottom-container a {
  color: #808182;
  font-size: 1.0833em;
}

.copyright {
  margin: 23px 20px 23px;
  line-height: 2em;

  @media (max-width: 991px) {
    margin-bottom: 20px;
  }
}

.back-to-top {
  display: flex;
  align-items: center;

  @media (max-width: 991px) {
    margin-bottom: 20px;
  }
}

.arrow-top {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 1.8em;
  height: 1.8em;
  margin-left: 100px;
  font-weight: bold;
  color: #12a0c3;
  border: 1px #000166 solid;
  border-radius: 50% 50% 50% 50%;
  line-height: 1.7333em;
  cursor: pointer;

  @media (max-width: 991px) {
    margin-left: 150px;
  }
}

.arrow-top:hover {
  &.arrow-top i {
    animation-name: moveUp;
    animation-duration: 0.5s;
  }
}

@keyframes moveUp {
  0% {
    margin-bottom: 0px;
    opacity: 1;
  }
  35% {
    margin-bottom: 50px;
    opacity: 1;
  }
  40% {
    margin-bottom: 50px;
    opacity: 1;
  }
  42% {
    margin-bottom: 50px;
    opacity: 0;
  }
  45% {
    margin-bottom: -50px;
    opacity: 0;
  }
  47% {
    margin-bottom: -50px;
    opacity: 1;
  }
  60% {
    margin-bottom: -50px;
    opacity: 1;
  }
  90% {
    margin-bottom: 0px;
    opacity: 1;
  }
}
</style>
