<template>
  <div v-if="loading" class="loading-screen">
    <i class="fa-solid fa-spinner"></i>
  </div>
  <div v-else>
    <el-container>
      <!-- Header -->
      <el-header class="top-nav">
        <div class="container">
          <div>
            <h3>{{ $t("domesticTours") }}</h3>
          </div>
          <div class="nav-title">
            <a class="router-link" style="margin-right: 5px"
              ><router-link class="nav-route" to="/">{{
                $t("homePage")
              }}</router-link></a
            >
            /
            <a style="margin-left: 5px">{{ $t("domesticTours") }}</a>
          </div>
        </div>
      </el-header>
      <el-main class="main-content">
        <div class="container">
          <!-- Search Section -->
          <div class="search-menu">
            <h3>{{ $t("findTourProgram") }}</h3>
            <!-- Search Option -->
            <div class="search-option">
              <el-input
                class="search-field"
                v-model="searchKeyword"
                style="height: 32px; margin: 0 5px 0 5px"
                :placeholder="$t('enterKeyword')"
              />
              <el-date-picker
                class="search-field"
                v-model="periodValue"
                style="height: 32px; margin: 0 5px 0 5px"
                type="daterange"
                unlink-panels
                range-separator="To"
                :start-placeholder="$t('startDate')"
                :end-placeholder="$t('endDate')"
                value-format="x"
                :shortcuts="shortcuts"
              />

              <el-select
                class="search-field"
                v-model="provinceValue"
                :placeholder="'--' + $t('selectProvince') + '--'"
                style="margin: 0 5px 0 5px"
              >
                <el-option
                  v-for="item in provinceOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>

              <button @click="navigateToSearch()">
                <i class="fa-solid fa-magnifying-glass"></i>{{ $t("search") }}
              </button>
            </div>
          </div>
          <!-- Result List -->
          <h1>{{ $t("domesticTours") }}</h1>
          <div class="domestic-list">
            <div
              class="item"
              v-for="(regionItem, index) in regionArray"
              :key="index"
            >
              <domesticOption
                :title="regionItem.title"
                :category="regionItem.id"
                :image="regionItem.image"
                :quantity="regionItem.plan_total"
              />
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import domesticOption from "../../components/item/domesticOption.vue";

export default {
  name: "App",
  components: {
    domesticOption,
  },
  data() {
    return {
      // 日期 样本选项
      shortcuts: [
        {
          text: "Last week",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          },
        },
        {
          text: "Last month",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          },
        },
        {
          text: "Last 3 months",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          },
        },
      ],
      searchKeyword: this.$route.query.keyword,
      periodValue: [],
      provinceValue: this.$route.query.province || 2,

      itemsPerPage: 8,
      currentPage: 1,

      loading: true,

      tourCounts: {},
    };
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 300);
  },
  computed: {
    /*
     * 获取该页面可显示的物件数量 (一个页面最多八个物件，以此类推)
     */
    paginatedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.regionArray.slice(startIndex, endIndex);
    },

    /**
     *  从store获取地区/州属列表
     */
    regionArray() {
      return this.$store.getters.getStateList;
    },

    /**
     * 搜索设置 地区/州属选项
     */
    provinceOptions() {
      let optionList = [
        { value: 2, label: "--" + this.$t("selectProvince") + "--" },
      ];
      this.regionArray.forEach((region) => {
        optionList.push({
          value: region.id,
          label: region.title,
        });
      });
      return optionList;
    },

    /*
     * 获取计算后的页面总数
     */
    totalPages() {
      return Math.ceil(this.regionArray.length / this.itemsPerPage);
    },
  },
  methods: {
    /*
     * 搜索国内旅游方案
     */
    navigateToSearch() {
      // 设置请求
      let queryParams = {
        category: this.provinceValue,
      };

      // 根据已设置的搜索条件加入 请求
      if (this.searchKeyword && this.searchKeyword != "")
        queryParams.keyword = this.searchKeyword;

      if (this.startDate && this.startDate != "")
        queryParams.startDate = this.startDate / 1000;

      if (this.periodValue.length > 0) {
        queryParams.endDate = this.periodValue[0] / 1000;
        queryParams.endDate = this.periodValue[1] / 1000;
      }

      this.$router.push({
        path: "/domestic_tour/search",
        query: queryParams,
      });
    },

    /**
     * 显示下个页面的物件
     */
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        window.scrollTo(0, 0);
      }
    },

    /*
     * 显示上个页面的物件
     */
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        window.scrollTo(0, 0);
      }
    },
  },
};
</script>
<style scoped>
/* Top Nav */
.top-nav {
  display: block;
  width: 100%;
  height: 56px;
  background: #2d3e52;

  @media (max-width: 991px) {
    width: auto;
  }
}

.top-nav .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1170px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  color: #5a7ca3;
  font-weight: bold;

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 100%;
  }
}

.top-nav a {
  color: #fdb714;
  font-size: 1em;
  text-decoration: none;
}

.top-nav h3 {
  color: #fff;
  font-weight: 300;
  text-decoration: none;
}

router-link {
  text-decoration: none;
}

.top-nav .router-link:hover {
  color: #12a0c3;
}

.nav-title {
  display: block;
  @media (max-width: 991px) {
    display: none;
  }
}

/* Body */
.main-content {
  display: block;
  width: 100%;
  background: #f5f5f5;

  @media (max-width: 991px) {
    width: auto;
  }
}

.main-content .container {
  width: 1170px;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 95%;
  }

  @media (max-width: 991px) {
    width: auto;
    padding: 20px 5px;
  }
}

/* Search Section */
.search-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  padding: 0 30px 30px 30px;
  background: white;
  border: solid 1px #ddd;
  border-radius: 8px;

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.search-menu h3 {
  color: #000166;
}

.search-menu a {
  line-height: 50px;
}

/* Search Option */
.search-option {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 991px) {
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
  }
}

.search-field {
  width: 25%;

  @media (max-width: 991px) {
    width: 100% !important;
    margin-bottom: 10px !important;
  }
}

.search-option::v-deep .el-date-editor {
  @media (max-width: 991px) {
    width: 97.5% !important;
    margin-bottom: 10px !important;
  }
}

.search-option button {
  margin-left: 5px;
  padding: 12px;
  color: #fff;
  font-size: 0.95em;
  font-weight: bold;
  background: #000166;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  @media (max-width: 991px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
  }
}

.search-option button i {
  padding-right: 5px;
}

/* Result List */
h1 {
  width: 100%;
  margin-bottom: 15px;
  padding: 15px 0 15px 0;
  color: #000166;
  background: white;
  text-align: center;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.15);
}

/* Tour List */
.domestic-list {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;
  flex-direction: row;

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.item {
  width: calc((100% / 4));

  @media (max-width: 991px) {
    width: 100%;
  }
}

/* Loading */
.loading-screen {
  width: 100%;
  height: 300px;
  text-align: center;
  padding-top: 50px;
}

.loading-screen i {
  color: #828282;
  font-size: 3em;

  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
