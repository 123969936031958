<template>
  <div>
    <el-container>
      <!-- Header -->
      <el-header class="top-nav">
        <div class="container">
          <a class="router-link" style="margin-right: 5px"
            ><router-link class="nav-route" to="/">{{
              $t("homePage")
            }}</router-link></a
          >
          /
          <a style="margin-left: 5px">{{ $t("airlineTicket") }}</a>
        </div>
      </el-header>

      <!-- Body -->
      <el-main class="main-content">
        <div class="container">
          <!-- Title -->
          <div class="title">
            <h1>
              {{ $t("airlineTicket") }}
            </h1>
            <img
              class="img-responsive"
              :alt="this.$t('airlineTicket')"
              :src="companyProfileImage"
              @error="$event.target.src = require('../assets/no-image.svg')"
            />
          </div>
          <!-- Info -->
          <div class="info-panel">
            <a>{{ $t("airlineTicket") }}</a>
          </div>
          <div class="info-panel">
            <h4>
              <i class="fa-solid fa-person"></i>
              {{ $t("bookSingle") }}
            </h4>
            <a style="padding-left: 30px">{{ $t("bookSingle") }}</a>
            <div class="flight-list">
              <img
                class="flight-img"
                v-for="(image, index) in planeArray1"
                :key="index"
                :src="require('../assets/airline/' + image)"
                alt="Air Ticket"
                @error="$event.target.src = require('../assets/no-image.svg')"
              />
            </div>
          </div>
          <div class="info-panel">
            <h4>
              <i class="fa-solid fa-people-group"></i>
              {{ $t("bookGroup") }}
            </h4>
            <a style="padding-left: 30px">{{ $t("bookGroup") }}</a>
            <div class="flight-list">
              <img
                class="flight-img"
                v-for="(image, index) in planeArray2"
                :key="index"
                :src="require('../assets/airline/' + image)"
                alt="Air Ticket"
                @error="$event.target.src = require('../assets/no-image.svg')"
              />
            </div>
          </div>
          <!-- Flight List -->
          <div class="info-panel">
            <h4>
              <i class="fa-solid fa-plane"></i>
              {{ $t("airlineWeServe") }}
            </h4>
            <div class="flight-list">
              <img
                class="flight-img"
                v-for="(image, index) in planeArray3"
                :key="index"
                :src="require('../assets/airline/' + image)"
                alt="Air Ticket"
                @error="$event.target.src = require('../assets/no-image.svg')"
              />
            </div>
          </div>
          <!-- Contact -->
          <div class="button-list">
            <button
              class="call-button"
              @click="navigateTo(`tel:${this.phoneNumber}`)"
            >
              <i class="fa-solid fa-phone"></i>{{ $t("callReserve") }}
            </button>
            <button
              class="line-button web-line"
              @click="showReserveModal = true"
            >
              <i class="fa-brands fa-line"></i>{{ $t("bookOnline") }}
            </button>
            <button
              class="line-button mobile-line"
              @click="moveToReserveLink()"
            >
              <i class="fa-brands fa-line"></i>{{ $t("bookOnline") }}
            </button>
          </div>
          <!-- Social Media -->
          <div class="social-media">
            <div class="social-list">
              <!-- Facebook -->
              <a
                :href="`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                  currentLink
                )}&display=popup&ref=plugin&src=like`"
              >
                <i
                  class="fa-brands fa-facebook social-img"
                  alt="Share on Facebook"
                ></i>
              </a>
              <!-- Messenger -->
              <a
                :href="`https://www.messenger.com/sharer.php?u=${encodeURIComponent(
                  currentLink
                )}`"
              >
                <i
                  class="fa-brands fa-facebook-messenger social-img"
                  alt="Share on Messenger"
                ></i>
              </a>
              <!-- Line -->
              <a
                :href="`https://line.me/R/msg/text/?${encodeURIComponent(
                  currentLink
                )}`"
              >
                <i class="fa-brands fa-line social-img" alt="Share on Line"></i>
              </a>
              <!-- Email -->
              <a
                :href="`mailto:?subject=&body=${encodeURIComponent(
                  currentLink
                )}`"
              >
                <i
                  class="fa-regular fa-envelope social-img"
                  alt="Share on Email"
                ></i>
              </a>
              <!-- Twitter -->
              <a
                :href="`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                  currentLink
                )}`"
              >
                <i
                  class="fa-brands fa-x-twitter social-img"
                  alt="Share on Twitter"
                ></i>
              </a>
            </div>
            <!-- Airline Ticket Link -->
            <div class="link">
              <input
                ref="inputField"
                type="text"
                :value="currentLink"
                class="input-field"
                @click="copyToClipboard"
              />
              <button class="copy-button" @click="copyToClipboard">
                <i class="fa-solid fa-link" style="padding-right: 5px"></i
                >{{ $t("copy") }}
              </button>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
  <!-- Reserve Modal -->
  <Transition name="modal">
    <ReserveModal
      class="modal"
      v-if="showReserveModal"
      :reserveLink="`https://line.me/R/ti/p/%40${line}`"
      @closeModal="closeReserveModal"
    ></ReserveModal>
  </Transition>
</template>
<script>
import ReserveModal from "../components/reserveModal.vue";

export default {
  name: "App",
  components: {
    ReserveModal,
  },
  data: function () {
    return {
      currentLink: window.location.href,
      phoneNumber: "09-8570-8809",
      showReserveModal: false,

      // 航空公司 图片
      planeArray1: ["flydubai.jpg", "ryanair.jpg", "shanghaiairlines.jpg"],
      planeArray2: ["indigo.jpg", "cebupacific.jpg", "jetstar.jpg"],
      planeArray3: [
        "airasia.jpg",
        "thaiairline.jpg",
        "nokair.jpg",
        "vietjetair.jpg",
      ],
    };
  },
  computed: {
    companyProfileImage() {
      return this.$store.getters.getCompanyProfileImage;
    },

    line() {
      return this.$store.getters.getCompanyLineId;
      // return "gtq9153t";
    },
    // planArray() {
    //   return this.$store.getters.getPlaneArray;
    // },
  },
  methods: {
    /**
     * 关闭预约弹窗
     */
    closeReserveModal() {
      this.showReserveModal = false;
    },

    /**
     * 手机号码联系店家
     * 或
     * 查看文件
     */
    navigateTo(link) {
      window.open(link, "_blank");
    },

    /**
     * 手机界面,
     * 点击后会跳至 Line页面，联系店家
     */
    moveToReserveLink() {
      window.open(`https://line.me/R/ti/p/%40${this.line}`);
    },

    /*
     * 复制框内网址 (订购机票网址)
     */
    copyToClipboard() {
      this.$refs.inputField.select();
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
    },
  },
};
</script>
<style scoped>
/* Top Nav */
.top-nav {
  display: block;
  width: 100%;
  height: 56px;
  background: #2d3e52;

  @media (max-width: 991px) {
    display: none;
  }
}

.top-nav .container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 1170px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  color: #5a7ca3;
  font-weight: bold;

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 100%;
  }
}

.top-nav a {
  color: #fdb714;
  font-size: 1em;
  text-decoration: none;
}

router-link {
  text-decoration: none;
}

.top-nav .router-link:hover {
  color: #12a0c3;
}

/* Body */
.main-content {
  display: block;
  width: 100%;
  padding: 0px;
  background: #f5f5f5;
}

.main-content .container {
  width: 1170px;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;
  background: white;

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 95%;
  }

  @media (max-width: 991px) {
    width: auto;
  }
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title h1 {
  color: #000166;
  font-weight: bold;
  text-align: center;

  @media (max-width: 991px) {
    font-size: 1.3em;
  }
}

.title img {
  width: 50%;
  height: auto;

  @media (max-width: 991px) {
    width: 100%;
  }
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
  margin-bottom: 15px;

  @media (max-width: 991px) {
    margin-bottom: 30px;
  }
}

h4 {
  margin-top: 0;
  color: #000166;
  font-weight: bold;
  font-size: 1.3em;
}

/* Info Panel */
.info-panel {
  width: auto;
  padding: 35px 60px;
  border-top: solid 1px #ddd;

  @media (max-width: 991px) {
    padding: 30px 40px;
  }
}

.info-panel a {
  color: #4d4c4c;

  @media (max-width: 991px) {
    line-height: 25px;
  }
}

.flight-list {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
  }
}

.flight-img {
  width: 220px;
  height: 130px;
  border: solid 1px #ddd;
  border-radius: 7px;
  margin-bottom: 10px;
}

/* Contact Panel */
.button-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 15px;

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.button-list button {
  width: 300px;
  padding: 5px 0 5px;
  margin: 5px;
  background: #fff;
  font-size: 20px;
  border-radius: 8px;
  cursor: pointer;
  @media (max-width: 991px) {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

.call-button i,
.line-button i {
  padding-right: 20px;
}

.call-button {
  color: #2ccbc1;
  border: 2px #2ccbc1 solid;
}

.call-button:hover {
  background: #2ccbc1;
  color: #fff;
}

.line-button {
  color: #00b900;
  border: 2px #00b900 solid;
}

.line-button:hover {
  background: #00b900;
  color: #fff;
}

.web-line {
  display: block;

  @media (max-width: 991px) {
    display: none;
  }
}

.mobile-line {
  display: none;

  @media (max-width: 991px) {
    display: block;
  }
}

/* Social Media */
.social-media {
  display: flex;
  flex-direction: column;
  text-align: right;
  padding-right: 4%;
  padding-bottom: 3%;
  margin-top: 20px;
  @media (max-width: 991px) {
    padding: 0;
  }
}

.social-media .social-list {
  @media (max-width: 991px) {
    display: flex;
    justify-content: center;
  }
}

.social-media a {
  margin-top: 15px;
  margin-bottom: 15px;
}

.social-img {
  padding: 8px;
  margin: 0 3px;
  color: white;
  font-size: 30px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}

.fa-facebook {
  background: #4267b2;
}

.fa-facebook:hover {
  background: #3a5a9b;
}

.fa-facebook-messenger {
  background: #168aff;
}

.fa-facebook-messenger:hover {
  background: #1582ef;
}

.social-list .fa-line {
  background: #06c755;
}

.social-list .fa-line:hover {
  background: #06b54f;
}

.fa-envelope {
  background: rgb(199, 41, 41);
}

.fa-envelope:hover {
  background: rgb(187, 39, 39);
}

.fa-x-twitter {
  background: #000000;
}

.fa-x-twitter:hover {
  background: #000000b7;
}

.link {
  @media (max-width: 991px) {
    display: flex;
    margin: 20px 0;
  }
}

.link .input-field {
  width: 300px;
  line-height: 35px;
  padding-left: 20px;
  margin-top: 20px;
  border: none;
  border-radius: 0;
  font-size: 0.9em;
  background: #fbfcec;
  cursor: auto;

  @media (max-width: 991px) {
    width: 70%;
    margin: 0;
  }
}

.copy-button {
  padding: 0 10px 0 10px;
  line-height: 35px;
  font-size: 0.9em;
  border: none;
  border-radius: 0;
  background: #000166;
  color: white;
  cursor: pointer;

  @media (max-width: 991px) {
    width: 30%;
  }
}

/* Reserve Modal */
.modal {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  left: 0;
  top: 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);

  @media (max-width: 991px) {
    display: none;
  }
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.2s;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}
</style>
