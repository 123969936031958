import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import store from "./store/store.js";
import "maz-ui/styles";
import i18n from "./locales/i18n.js";
import moment from "moment";

const app = createApp(App);

app.config.globalProperties.$moment = moment;

app.use(router).use(ElementPlus).use(store).use(i18n).mount("#app");
