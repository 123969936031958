<template>
  <div v-if="loading" class="loading-screen">
    <i class="fa-solid fa-spinner"></i>
  </div>
  <div v-else>
    <el-container>
      <!-- Header -->
      <el-header class="top-nav">
        <div class="container">
          <div>
            <h3 v-if="keyword || countryId || brandValue || typeValue">
              {{ $t("searchTerm") }}: "{{ keyword }}"
            </h3>
            <h3 v-else>{{ $t("carRental") }}</h3>
          </div>
          <div class="nav-title">
            <a class="router-link" style="margin-right: 5px"
              ><router-link class="nav-route" to="/">{{
                $t("homePage")
              }}</router-link></a
            >
            /
            <a style="margin-left: 5px">{{ $t("carRental") }}</a>
          </div>
        </div>
      </el-header>
      <el-main class="main-content">
        <div class="container">
          <div class="top-panel">
            <!-- Search Section -->
            <div class="search-menu">
              <h3>{{ $t("findCar") }}</h3>
              <div class="resultText">
                <a>{{ $t("found") }} {{ totalCount }}</a
                ><a
                  style="color: #000166; font-weight: bold; padding-left: 5px"
                >
                  {{ $t("car") }}</a
                >
              </div>
              <!-- Search Option -->
              <div class="search-option">
                <a>{{ $t("enterKeyword") }}</a>
                <el-input
                  v-model="keyword"
                  style="width: 235px; height: 32px; margin: 0 5px 0 5px"
                  :placeholder="$t('enterKeyword')"
                />
                <a>{{ $t("country") }}</a>
                <el-select
                  v-model="countryId"
                  :placeholder="'--' + $t('selectCountry') + '--'"
                  style="width: 235px; margin: 0 5px 0 5px"
                >
                  <el-option
                    v-for="item in countryOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
                <a>{{ $t("carBrand") }}</a>
                <el-select
                  v-model="brandValue"
                  :placeholder="'--' + $t('selectCarBrand') + '--'"
                  style="width: 235px; margin: 0 5px 0 5px"
                >
                  <el-option
                    v-for="item in brandOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
                <a>{{ $t("transimission") }}</a>
                <el-select
                  v-model="typeValue"
                  :placeholder="'--' + $t('selectTransmission') + '--'"
                  style="width: 235px; margin: 0 5px 0 5px"
                >
                  <el-option
                    v-for="item in typeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>

                <button @click="navigateToSearch()">
                  <i class="fa-solid fa-magnifying-glass"></i>{{ $t("search") }}
                </button>
              </div>
              <!-- Search Result Text -->
            </div>
            <!-- Carousel -->
            <el-carousel class="car-carousel" indicator-position="outside">
              <el-carousel-item v-for="carImg in carouselList" :key="carImg">
                <img
                  :src="require('../../assets/banner/' + carImg)"
                  @error="
                    $event.target.src = require('../../assets/no-image.svg')
                  "
                />
              </el-carousel-item>
            </el-carousel>
          </div>
          <!-- Result List -->
          <div class="car-list">
            <div class="item" v-for="(carItem, index) in carArray" :key="index">
              <CarItem
                :code="carItem.code"
                :id="carItem.id"
                :brandLogo="carItem.brand_image"
                :title="carItem.title"
                :location="carItem.location"
                :image="carItem.image"
                :price="carItem.price"
                :currency="carItem.currency"
              ></CarItem>
            </div>
          </div>
        </div>
      </el-main>
      <el-footer>
        <!-- Pagination -->
        <div class="pagination">
          <button
            class="previous-button"
            @click="previousPage"
            :disabled="currentPage === 1"
          >
            {{ $t("previousPage") }}
          </button>
          <div v-for="(pageNumber, index) in visiblePages" :key="pageNumber">
            <a
              style="font-weight: bold"
              v-if="index !== 0 && visiblePages[index - 1] !== pageNumber - 1"
              >...</a
            >
            <span
              :class="
                'page-button' + [currentPage == pageNumber ? ' disabled' : '']
              "
              v-if="totalPages > 1"
              :key="pageNumber"
              @click="gotoPage(pageNumber)"
              :disabled="currentPage === pageNumber"
            >
              {{ pageNumber }}</span
            >
          </div>
          <span class="page-button disabled" v-if="totalPages < 2">1</span>
          <button
            class="next-button"
            @click="nextPage"
            :disabled="currentPage === totalPages || totalPages === 0"
          >
            {{ $t("nextPage") }}
          </button>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
import CarItem from "../../components/item/carItem.vue";

import axios from "axios";
import config from "../../api/config.json";

export default {
  name: "App",
  components: {
    CarItem,
  },
  data() {
    return {
      // 租车跑马灯图
      carouselList: ["car_banner1.jpg", "car_banner2.jpg"],
      // 目的地 样本选项
      typeOptions: [
        { value: "", label: "--" + this.$t("selectTransmission") + "--" },
        { value: "automatic", label: "Automatic" },
        { value: "manual", label: "Manual" },
      ],
      keyword: this.$route.query.keyword || "",
      countryId: Number(this.$route.query.location_id) || "",
      brandValue: Number(this.$route.query.brand_id) || "",
      typeValue: this.$route.query.gear || "",

      itemsPerPage: 8,
      currentPage: 1,
      totalCount: 0,

      loading: true,

      // 租车 数据
      carArray: [],
    };
  },
  mounted() {
    this.fetchCarData();
  },
  computed: {
    /**
     *  从store获取国家列表
     */
    countryArray() {
      return this.$store.getters.getCountryList;
    },

    /**
     * 搜索设置 国家选项
     */
    countryOptions() {
      let optionList = [
        { value: "", label: "--" + this.$t("selectCityCountry") + "--" },
      ];

      this.countryArray.forEach((country) => {
        optionList.push({
          value: country.id,
          label: country.title,
        });
      });

      return optionList;
    },

    /**
     *  从store获取汽车品牌列表
     */
    carBrandArray() {
      return this.$store.getters.getCarBrandList;
    },

    /**
     * 搜索设置 汽车品牌选项
     */
    brandOptions() {
      let optionList = [
        { value: "", label: "--" + this.$t("selectCarBrand") + "--" },
      ];

      this.carBrandArray.forEach((brand) => {
        optionList.push({
          value: brand.id,
          label: brand.title,
        });
      });

      return optionList;
    },

    /*
     * 获取计算后的页面总数
     */
    totalPages() {
      return Math.ceil(this.carArray.length / this.itemsPerPage);
    },

    /**
     * 获取当前显示的页面号码
     */
    visiblePages() {
      const totalPages = this.totalPages;
      const currentPage = this.currentPage;

      let visiblePages = [];
      visiblePages.push(1);

      for (let i = 1; i <= totalPages; i++) {
        if (currentPage == i && !visiblePages.includes(i)) {
          visiblePages.push(i);
        } else if (i === totalPages && !visiblePages.includes(i)) {
          visiblePages.push(i);
        } else if (i === currentPage - 1 && !visiblePages.includes(i)) {
          visiblePages.push(i);
        } else if (i === currentPage + 1 && !visiblePages.includes(i)) {
          visiblePages.push(i);
        }
      }

      return visiblePages;
    },
  },
  methods: {
    /**
     * 获取 租车列表 数据
     */
    async fetchCarData() {
      let queryParams = {};

      if (this.keyword && this.keyword != "")
        queryParams.keyword = this.keyword;

      if (this.countryId && this.countryId != "")
        queryParams.location_id = this.countryId;

      if (this.brandValue && this.brandValue != "")
        queryParams.brand_id = this.brandValue;

      if (this.typeValue && this.typeValue != "")
        queryParams.gear = this.typeValue;

      queryParams.page = this.currentPage;
      queryParams.limit = this.itemsPerPage;

      // 显示加载中
      this.loading = true;

      // 获取租车数据
      await axios
        .get(`${config.apiBaseUrl}/${config.version}/car/rental`, {
          params: queryParams,
        })
        .then((response) => {
          this.carArray = response.data.data;
          this.totalCount = response.data.count;
        })
        .catch((error) => {
          // 发生错误
          console.error("Error fetching cars:", error);
        })
        .finally(() => {
          // 执行就结束，结束加载中
          this.loading = false;
        });
    },

    /**
     * 显示下个页面的物件
     */
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchCarData();
        window.scrollTo(0, 0);
      }
    },

    /*
     * 显示上个页面的物件
     */
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchCarData();
        window.scrollTo(0, 0);
      }
    },

    /*
     * 移动至选定页面
     */
    gotoPage(pageNumber) {
      this.currentPage = pageNumber;
      this.fetchCarData();
      window.scrollTo(0, 0);
    },

    /**
     * 搜索 游轮配套
     */
    navigateToSearch() {
      let queryParams = {};

      if (this.keyword && this.keyword != "")
        queryParams.keyword = this.keyword;

      if (this.countryId && this.countryId != "")
        queryParams.location_id = this.countryId;

      if (this.brandValue && this.brandValue != "")
        queryParams.brand_id = this.brandValue;

      if (this.typeValue && this.typeValue != "")
        queryParams.gear = this.typeValue;

      this.$router.push({
        path: `/car_rental`,
        query: queryParams,
      });
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style scoped>
/* Top Nav */
.top-nav {
  display: block;
  width: 100%;
  height: 56px;
  background: #2d3e52;

  @media (max-width: 991px) {
    width: auto;
  }
}

.top-nav .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1170px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  color: #5a7ca3;
  font-weight: bold;

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 100%;
  }
}

.top-nav a {
  color: #fdb714;
  font-size: 1em;
  text-decoration: none;
}

.top-nav h3 {
  color: #fff;
  font-weight: 300;
  text-decoration: none;
}

router-link {
  text-decoration: none;
}

.top-nav .router-link:hover {
  color: #12a0c3;
}

.nav-title {
  display: block;
  @media (max-width: 991px) {
    display: none;
  }
}

/* Body */
.main-content {
  display: block;
  width: 100%;
  background: #f5f5f5;

  @media (max-width: 991px) {
    width: auto;
  }
}

.main-content .container {
  width: 1170px;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 95%;
  }

  @media (max-width: 991px) {
    width: auto;
    padding: 20px 5px;
  }
}

/* Top Panel */
.top-panel {
  display: flex;
  flex-direction: row;

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

/* Carousel */
.car-carousel {
  width: 68%;

  @media (max-width: 991px) {
    width: 100%;
  }
}

.car-carousel::v-deep .el-carousel__container {
  height: 400px;

  img {
    width: 100%;
  }

  @media (max-width: 991px) {
    width: 100%;
    height: 45vw;
  }
}

/* Search Section */
.search-menu {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  background: white;
  border: solid 1px #ddd;

  @media (min-width: 991px) {
    min-width: 280px;
  }

  @media (max-width: 991px) {
    width: 100%;
    padding: 0;
  }
}

.search-menu h3 {
  color: #000166;
  margin-bottom: 5px;
}

.resultText {
  font-size: 0.8em;
  margin-bottom: 10px;
}

/* Search Option */
.search-option {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-option a {
  font-size: 0.9em;
  line-height: 30px;
}

.search-option button {
  width: 100%;
  margin-top: 10px;
  padding: 12px;
  color: #fff;
  font-size: 0.95em;
  font-weight: bold;
  background: #000166;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  @media (max-width: 991px) {
    margin-bottom: 25px;
  }
}

.search-option button i {
  padding-right: 5px;
}

/* Car List */
.car-list {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;
  flex-direction: row;

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.item {
  width: calc((100% / 4));

  @media (max-width: 991px) {
    width: 100%;
  }
}

/* Pagination */
.el-footer {
  background: #f5f5f5;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 20px 0;
  font-weight: bold;
}

.pagination button {
  padding: 12px;
  color: #fff;
  font-size: 0.95em;
  font-weight: bold;
  background: #000166;
  border: none;
  cursor: pointer;
}

.pagination button:disabled {
  color: #fff;
  background: #d9d9d9;
  border: none;
  cursor: context-menu;
}

.previous-button {
  margin-right: 5px;
}

.next-button {
  margin-left: 5px;
}

.pagination .page-button {
  margin: 0 5px 0 5px;
  padding: 12px;
  color: #fff;
  background: #d9d9d9;
  font-size: 0.95em;
  text-align: center;
  border: none;
  cursor: context-menu;
}

.pagination .disabled,
.pagination .page-button:hover {
  background: #000166;
}

/* Loading */
.loading-screen {
  width: 100%;
  height: 300px;
  text-align: center;
  padding-top: 50px;
}

.loading-screen i {
  color: #828282;
  font-size: 3em;

  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
