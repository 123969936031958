<template>
  <TopHeader></TopHeader>

  <router-view :key="$route.fullPath"></router-view>

  <!-- Social Media Floating Box -->
  <SocialMediaFloatBox></SocialMediaFloatBox>

  <FooterBar></FooterBar>
</template>

<script>
import { useFavicon } from "@vueuse/core";
import { getCurrentInstance } from "vue";
import TopHeader from "./components/topHeader.vue";
import FooterBar from "./components/footerBar.vue";
import SocialMediaFloatBox from "./components/socialMediaFloatBox.vue";
import { useI18n } from "vue-i18n";

export default {
  name: "App",
  components: {
    TopHeader,
    FooterBar,
    SocialMediaFloatBox,
  },

  computed: {
    companySiteIcon() {
      return this.$store.getters.getCompanySiteIcon;
    },
    siteName() {
      return this.$store.getters.getSiteName;
    },
  },

  watch: {
    // 监听 并 使用 网址图标 及 网址名称
    companySiteIcon(icon) {
      useFavicon(icon);
    },
    siteName(name) {
      if (this.$route.path === "/") {
        document.title = name;
      }
    },
  },
  async beforeCreate() {
    // 设定语言包
    localStorage.setItem("locale", "th");
    let locale = localStorage.getItem("locale");

    const { t } = useI18n({ useScope: global });

    // 设定日期显示语言
    const moment =
      getCurrentInstance().appContext.config.globalProperties.$moment;

    moment.locale(locale, {
      months: t("months").split("_"),
      monthsShort: t("monthsShort").split("_"),
      monthsParseExact: true,
      weekdays: t("weekdays").split("_"),
      weekdaysShort: t("weekdaysShort").split("_"),
      weekdaysMin: t("weekdaysMin").split("_"),
      weekdaysParseExact: true,
    });

    // 从store获取data
    await this.$store.dispatch("fetchCompanyData").catch((error) => {
      console.error("Failed to fetch company data:", error);
    });

    await this.$store.dispatch("fetchCountryData").catch((error) => {
      console.error("Failed to fetch country data:", error);
    });

    await this.$store.dispatch("fetchStateData").catch((error) => {
      console.error("Failed to fetch state data:", error);
    });

    await this.$store.dispatch("fetchCarBrandData").catch((error) => {
      console.error("Failed to fetch car brand data:", error);
    });
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}

html,
body {
  @media (max-width: 991px) {
    overflow-x: hidden;
  }
}

body {
  margin: 0 !important;
}

/* Navigation Bar Route Link */
.nav-route {
  color: inherit !important;
  text-decoration: none !important;
}
</style>
