<template>
  <div v-if="loading" class="loading-screen">
    <i class="fa-solid fa-spinner"></i>
  </div>
  <div v-else>
    <el-container>
      <!-- Header -->
      <el-header class="top-nav">
        <div class="container">
          <div>
            <h3 v-if="keyword || keyword || countryValue">
              {{ $t("searchTerm") }}: "{{ keyword }}"
            </h3>
            <h3 v-else>{{ $t("admissionTicket") }}</h3>
          </div>
          <div>
            <a class="router-link" style="margin-right: 5px"
              ><router-link class="nav-route" to="/">{{
                $t("homePage")
              }}</router-link></a
            >
            /
            <a style="margin-left: 5px">{{ $t("admissionTicket") }}</a>
          </div>
        </div>
      </el-header>
      <el-main class="main-content">
        <div class="container">
          <!-- Search Section -->
          <div class="search-menu">
            <h3>{{ $t("searchAttraction") }}</h3>
            <!-- Search Option -->
            <div class="search-option">
              <el-input
                class="search-field"
                v-model="keyword"
                :placeholder="$t('enterKeyword')"
              />

              <el-select
                class="search-field"
                v-model="countryValue"
                :placeholder="'-- ' + $t('selectCityCountry') + ' --'"
              >
                <el-option
                  v-for="item in countryOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>

              <button @click="filterTicket()">
                <i class="fa-solid fa-magnifying-glass"></i>{{ $t("search") }}
              </button>
            </div>
            <!-- Search Result Text -->
            <div>
              <a>{{ $t("found") }} {{ ticketArray.length }} </a
              ><a style="color: #000166; font-weight: bold; padding: 0 5px">
                {{ $t("attractionTicket") }} </a
              ><a> {{ $t("packages") }}</a>
            </div>
          </div>
          <!-- Result List -->
          <div class="ticket-list">
            <div
              class="item"
              v-for="(ticketItem, index) in paginatedItems"
              :key="index"
            >
              <TicketItem
                :page="ticketItem.page"
                :id="ticketItem.id"
                :name="ticketItem.name"
                :countryName="ticketItem.countryName"
                :imageUrl="ticketItem.imageUrl"
                :price="ticketItem.price"
              ></TicketItem>
            </div>
          </div>
        </div>
      </el-main>
      <el-footer>
        <!-- Pagination -->
        <div class="pagination">
          <button
            class="previous-button"
            @click="previousPage"
            :disabled="currentPage === 1"
          >
            {{ $t("previousPage") }}
          </button>
          <div v-for="(pageNumber, index) in visiblePages" :key="pageNumber">
            <a
              style="font-weight: bold"
              v-if="index !== 0 && visiblePages[index - 1] !== pageNumber - 1"
              >...</a
            >
            <span
              :class="
                'page-button' + [currentPage == pageNumber ? ' disabled' : '']
              "
              v-if="totalPages > 1"
              :key="pageNumber"
              @click="gotoPage(pageNumber)"
              :disabled="currentPage === pageNumber"
            >
              {{ pageNumber }}</span
            >
          </div>
          <span class="page-button disabled" v-if="totalPages < 2">1</span>
          <button
            class="next-button"
            @click="nextPage"
            :disabled="currentPage === totalPages || totalPages === 0"
          >
            {{ $t("nextPage") }}
          </button>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
import TicketItem from "../../components/item/ticketItem.vue";
import ticketData from "../../json/ticket.json";
import axios from "axios";
import config from "../../api/config.json";

export default {
  name: "App",
  components: {
    TicketItem,
  },
  data() {
    return {
      keyword: "",
      countryValue: "",

      itemsPerPage: 8,
      currentPage: 1,

      loading: true,

      // 景点门票 数据
      ticketArray: ticketData,
      // ticketArray: [],
    };
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 500);

    // this.fetchTicketData();
  },
  computed: {
    /**
     *  从store获取国家列表
     */
    countryArray() {
      return this.$store.getters.getCountryList;
    },

    /**
     *  获取国家列表作为选项
     */
    countryOptions() {
      let optionList = [
        { value: "", label: "--" + this.$t("selectCityCountry") + "--" },
      ];

      this.countryArray
        .filter((country) => country.code)
        .forEach((country) => {
          optionList.push({
            value: country.code,
            label: country.name,
          });
        });

      return optionList;
    },

    /*
     * 获取该页面可显示的物件数量 (一个页面最多八个物件，以此类推)
     */
    paginatedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.ticketArray.slice(startIndex, endIndex);
    },

    /*
     * 获取计算后的页面总数
     */
    totalPages() {
      return Math.ceil(this.ticketArray.length / this.itemsPerPage);
    },

    /**
     * 获取当前显示的页面号码
     */
    visiblePages() {
      const totalPages = this.totalPages;
      const currentPage = this.currentPage;

      let visiblePages = [];
      visiblePages.push(1);

      for (let i = 1; i <= totalPages; i++) {
        if (currentPage == i && !visiblePages.includes(i)) {
          visiblePages.push(i);
        } else if (i === totalPages && !visiblePages.includes(i)) {
          visiblePages.push(i);
        } else if (i === currentPage - 1 && !visiblePages.includes(i)) {
          visiblePages.push(i);
        } else if (i === currentPage + 1 && !visiblePages.includes(i)) {
          visiblePages.push(i);
        }
      }

      return visiblePages;
    },
  },
  methods: {
    /**
     * 获取 景点门票 数据
     */
    async fetchTicketData() {
      let queryParams = {
        keyword: this.keyword || "",
        countryId: this.countryId || "",
      };

      try {
        // 显示加载中
        this.loading = true;

        // 获取景点门票数据
        const response = await axios.get(
          `${config.apiBaseUrl}/${config.version}/ticket`,
          {
            params: queryParams,
          }
        );
        console.log("Tickets", response.data);
      } catch (error) {
        // 发生错误
        console.error("Error fetching tickets:", error);
      } finally {
        // 执行就结束，结束加载中
        this.loading = false;
      }
    },

    /*
     * 根据搜索的条件，进行筛选该列表的所有物件
     */
    filterTicket() {
      this.ticketArray = ticketData;

      // 若没有任何搜索条件
      if (!this.keyword && !this.countryValue) {
        return this.ticketArray; // Return original packageArray if no filters are applied
      }

      // 若有搜索条件
      // 搜索条件
      this.ticketArray = this.ticketArray.filter((ticketItem) => {
        let checking = true;

        // 检查搜索字眼
        if (
          this.keyword &&
          !ticketItem.name.toLowerCase().includes(this.keyword.toLowerCase())
        ) {
          checking = false;
        }

        // 检查时间
        if (this.countryValue && ticketItem.country !== this.countryValue) {
          checking = false;
        }

        // 若全数符合，则不移除该配套
        return checking;
      });
    },

    /**
     * 显示下个页面的物件
     */
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        window.scrollTo(0, 0);
      }
    },

    /*
     * 显示上个页面的物件
     */
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        window.scrollTo(0, 0);
      }
    },

    /*
     * 移动至选定页面
     */
    gotoPage(pageNumber) {
      this.currentPage = pageNumber;
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style scoped>
/* Top Nav */
.top-nav {
  display: block;
  width: 100%;
  height: 56px;
  background: #2d3e52;

  @media (max-width: 991px) {
    width: auto;
  }
}

.top-nav .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1170px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  color: #5a7ca3;
  font-weight: bold;

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 100%;
  }
}

.top-nav a {
  color: #fdb714;
  font-size: 1em;
  text-decoration: none;
}

.top-nav h3 {
  color: #fff;
  font-weight: 300;
  text-decoration: none;
}

router-link {
  text-decoration: none;
}

.top-nav .router-link:hover {
  color: #12a0c3;
}

.nav-title {
  display: block;
  @media (max-width: 991px) {
    display: none;
  }
}

/* Body */
.main-content {
  display: block;
  width: 100%;
  background: #f5f5f5;

  @media (max-width: 991px) {
    width: auto;
  }
}

.main-content .container {
  width: 1170px;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 95%;
  }

  @media (max-width: 991px) {
    width: auto;
    padding: 20px 5px;
  }
}

/* Search Section */
.search-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  background: white;
  border: solid 1px #ddd;
  border-radius: 8px;

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.search-menu h3 {
  color: #000166;
}

.search-menu a {
  line-height: 50px;
}

/* Search Option */
.search-option {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 991px) {
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
  }
}

.search-option .search-field {
  width: 30%;
  height: 32px;

  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 10px !important;
  }
}

.search-option button {
  width: 30%;
  margin-left: 5px;
  padding: 10px;
  color: #fff;
  font-size: 0.9em;
  font-weight: bold;
  background: #000166;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  @media (max-width: 991px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
  }
}

.search-option button i {
  padding-right: 5px;
}

/* Ticket List */
.ticket-list {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;
  flex-direction: row;
  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.item {
  width: calc((100% / 4));

  @media (max-width: 991px) {
    width: 100%;
  }
}

/* Pagination */
.el-footer {
  background: #f5f5f5;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 20px 0;
  font-weight: bold;
}

.pagination button {
  padding: 12px;
  color: #fff;
  font-size: 0.95em;
  font-weight: bold;
  background: #000166;
  border: none;
  cursor: pointer;
}

.pagination button:disabled {
  color: #fff;
  background: #d9d9d9;
  border: none;
  cursor: context-menu;
}

.previous-button {
  margin-right: 5px;
}

.next-button {
  margin-left: 5px;
}

.pagination .page-button {
  margin: 0 5px 0 5px;
  padding: 12px;
  color: #fff;
  background: #d9d9d9;
  font-size: 0.95em;
  text-align: center;
  border: none;
  cursor: context-menu;
}

.pagination .disabled,
.pagination .page-button:hover {
  background: #000166;
}

/* Loading */
.loading-screen {
  width: 100%;
  height: 300px;
  text-align: center;
  padding-top: 50px;
}

.loading-screen i {
  color: #828282;
  font-size: 3em;

  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
