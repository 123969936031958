<template>
  <div v-if="loading" class="loading-screen">
    <i class="fa-solid fa-spinner"></i>
  </div>
  <div v-else>
    <el-container>
      <!-- Header -->
      <el-header class="top-nav">
        <div class="container">
          <div>
            <h3 v-if="keyword || brandValue || nameValue || destinationValue">
              {{ $t("searchTerm") }}: "{{ keyword }}"
            </h3>
            <h3 v-else>{{ $t("cruiseShip") }}</h3>
          </div>
          <div class="nav-title">
            <a class="router-link" style="margin-right: 5px"
              ><router-link class="nav-route" to="/">{{
                $t("homePage")
              }}</router-link></a
            >
            /
            <a style="margin-left: 5px">{{ $t("cruise") }}</a>
          </div>
        </div>
      </el-header>
      <el-main class="main-content">
        <div class="container">
          <!-- Carousel -->
          <el-carousel class="ship-carousel" indicator-position="outside">
            <el-carousel-item
              v-for="cruiseImg in carouselList"
              :key="cruiseImg"
            >
              <img
                :src="require('../../assets/banner/' + cruiseImg)"
                @error="
                  $event.target.src = require('../../assets/no-image.svg')
                "
              />
            </el-carousel-item>
          </el-carousel>
          <!-- Search Section -->
          <div class="search-menu">
            <h3>{{ $t("findCruise") }}</h3>
            <!-- Search Option -->
            <div class="search-option">
              <el-input
                class="search-field"
                v-model="keyword"
                style="height: 32px; margin: 0 5px 0 5px"
                :placeholder="$t('enterKeyword')"
              />

              <!-- <el-select
                class="search-field"
                v-model="brandValue"
                :placeholder="'--' + $t('selectBoatBrand') + '--'"
                style="margin: 0 5px 0 5px"
              >
                <el-option
                  v-for="item in brandOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>

              <el-select
                class="search-field"
                v-model="nameValue"
                :placeholder="'--' + $t('selectBoatName') + '--'"
                style="margin: 0 5px 0 5px"
              >
                <el-option
                  v-for="item in nameOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>

              <el-select
                class="search-field"
                v-model="destinationValue"
                :placeholder="'--' + $t('selectDestination') + '--'"
                style="margin: 0 5px 0 5px"
              >
                <el-option
                  v-for="item in destinationOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select> -->

              <button @click="navigateToSearch()">
                <i class="fa-solid fa-magnifying-glass"></i>{{ $t("search") }}
              </button>
            </div>
            <!-- Search Result Text -->
            <div>
              <a>{{ $t("found") }} {{ totalCount }}</a
              ><a style="color: #000166; font-weight: bold; padding: 0 5px">
                {{ $t("cruiseShip") }}</a
              >
            </div>
          </div>
          <!-- Result List -->
          <div class="cruise-list">
            <div
              class="item"
              v-for="(cruiseItem, index) in cruiseArray"
              :key="index"
            >
              <CruiseItem
                :code="cruiseItem.code"
                :id="cruiseItem.id"
                :travelBy="cruiseItem.travel_by"
                :company="cruiseItem.company"
                :title="cruiseItem.title"
                :images="cruiseItem.images"
                :duration="cruiseItem.duration"
                :startDate="cruiseItem.start_date"
                :endDate="cruiseItem.end_date"
                :date="cruiseItem.date"
                :airfare="cruiseItem.airfare"
                :price="cruiseItem.price_per_person"
              />
            </div>
          </div>
        </div>
      </el-main>
      <el-footer>
        <!-- Pagination -->
        <div class="pagination">
          <button
            class="previous-button"
            @click="previousPage"
            :disabled="currentPage === 1"
          >
            {{ $t("previousPage") }}
          </button>
          <div v-for="(pageNumber, index) in visiblePages" :key="pageNumber">
            <a
              style="font-weight: bold"
              v-if="index !== 0 && visiblePages[index - 1] !== pageNumber - 1"
              >...</a
            >
            <span
              :class="
                'page-button' + [currentPage == pageNumber ? ' disabled' : '']
              "
              v-if="totalPages > 1"
              :key="pageNumber"
              @click="gotoPage(pageNumber)"
              :disabled="currentPage === pageNumber"
            >
              {{ pageNumber }}</span
            >
          </div>
          <span class="page-button disabled" v-if="totalPages < 2">1</span>
          <button
            class="next-button"
            @click="nextPage"
            :disabled="currentPage === totalPages || totalPages === 0"
          >
            {{ $t("nextPage") }}
          </button>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
import CruiseItem from "../../components/item/cruiseItem.vue";

import axios from "axios";
import config from "../../api/config.json";

export default {
  name: "App",
  components: {
    CruiseItem,
  },
  data() {
    return {
      // 游轮跑马灯图
      carouselList: [
        "cruise_banner1.jpg",
        "cruise_banner2.jpg",
        "cruise_banner3.jpg",
      ],
      // 游轮品牌 样本选项
      brandOptions: [
        { value: "", label: "--" + this.$t("selectBoatBrand") + "--" },
        { value: 49, label: "Carnival Cruises Line" },
        { value: 5, label: "Costa Cruises" },
        { value: 42, label: "Dream Cruise" },
        { value: 19, label: "MSC Cruises" },
        { value: 38, label: "Uniworld Boutique River Cruises" },
      ],
      // 游轮名称 样本选项
      nameOptions: [
        { value: "", label: "--" + this.$t("selectBoatName") + "--" },
        { value: 73, label: "Carnival Luminosa" },
        { value: 49, label: "Costa Toscana" },
        { value: 6, label: "Genting Dream" },
        { value: 12, label: "MSC Bellissima" },
        { value: 64, label: "Resorts World One" },
        { value: 74, label: "S.S. Victoria" },
        { value: 60, label: "SS La Venezia" },
        { value: 56, label: "SS Maria Theresa" },
      ],
      // 目的地 样本选项
      destinationOptions: [
        { value: "", label: "--" + this.$t("selectDestination") + "--" },
        { value: 39, label: "Amsterdam" },
        { value: 40, label: "Budapest" },
        { value: 52, label: "Dubai" },
        { value: 16, label: "Hong Kong" },
        { value: 26, label: "Okinawa" },
        { value: 20, label: "Seattle" },
        { value: 15, label: "Singapore" },
        { value: 18, label: "Taiwan" },
        { value: 52, label: "Venice" },
      ],
      keyword: this.$route.query.keyword || "",
      brandValue: "",
      nameValue: "",
      destinationValue: "",

      itemsPerPage: 8,
      currentPage: 1,
      totalCount: 0,

      loading: true,

      // 游轮配套 数据
      cruiseArray: [],
    };
  },
  mounted() {
    this.fetchCruiseData();
  },
  computed: {
    /*
     * 获取计算后的页面总数
     */
    totalPages() {
      return Math.ceil(this.cruiseArray.length / this.itemsPerPage);
    },

    /**
     * 获取当前显示的页面号码
     */
    visiblePages() {
      const totalPages = this.totalPages;
      const currentPage = this.currentPage;

      let visiblePages = [];
      visiblePages.push(1);

      for (let i = 1; i <= totalPages; i++) {
        if (currentPage == i && !visiblePages.includes(i)) {
          visiblePages.push(i);
        } else if (i === totalPages && !visiblePages.includes(i)) {
          visiblePages.push(i);
        } else if (i === currentPage - 1 && !visiblePages.includes(i)) {
          visiblePages.push(i);
        } else if (i === currentPage + 1 && !visiblePages.includes(i)) {
          visiblePages.push(i);
        }
      }

      return visiblePages;
    },
  },
  methods: {
    /**
     * 获取 游轮方案 数据
     */
    async fetchCruiseData() {
      let queryParams = {};
      // company: this.bramdValue || "",
      // title: this.nameValue || "",
      // destination: this.destinationValue || "",

      if (this.keyword && this.keyword != "")
        queryParams.keyword = this.keyword;

      queryParams.page = this.currentPage;
      queryParams.limit = this.itemsPerPage;

      // 显示加载中
      this.loading = true;

      // 获取游轮方案数据
      await axios
        .get(`${config.apiBaseUrl}/${config.version}/cruise`, {
          params: queryParams,
        })
        .then((response) => {
          this.cruiseArray = response.data.data;
          this.totalCount = response.data.count;
        })
        .catch((error) => {
          // 发生错误
          console.error("Error fetching cruises:", error);
        })
        .finally(() => {
          // 执行就结束，结束加载中
          this.loading = false;
        });
    },

    /**
     * 显示下个页面的物件
     */
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchCruiseData();
        window.scrollTo(0, 0);
      }
    },

    /*
     * 显示上个页面的物件
     */
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchCruiseData();
        window.scrollTo(0, 0);
      }
    },

    /*
     * 移动至选定页面
     */
    gotoPage(pageNumber) {
      this.currentPage = pageNumber;
      this.fetchCruiseData();
      window.scrollTo(0, 0);
    },

    /**
     * 搜索 游轮配套
     */
    navigateToSearch() {
      let queryParams = {};

      if (this.keyword && this.keyword != "")
        queryParams.keyword = this.keyword;

      this.$router.push({
        path: `/cruise_list`,
        query: queryParams,
      });
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style scoped>
/* Top Nav */
.top-nav {
  display: block;
  width: 100%;
  height: 56px;
  background: #2d3e52;

  @media (max-width: 991px) {
    width: auto;
  }
}

.top-nav .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1170px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  color: #5a7ca3;
  font-weight: bold;

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 100%;
  }
}

.top-nav a {
  color: #fdb714;
  font-size: 1em;
  text-decoration: none;
}

.top-nav h3 {
  color: #fff;
  font-weight: 300;
  text-decoration: none;
}

router-link {
  text-decoration: none;
}

.top-nav .router-link:hover {
  color: #12a0c3;
}

.nav-title {
  display: block;
  @media (max-width: 991px) {
    display: none;
  }
}

/* Body */
.main-content {
  display: block;
  width: 100%;
  background: #f5f5f5;

  @media (max-width: 991px) {
    width: auto;
  }
}

.main-content .container {
  width: 1170px;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 95%;
  }

  @media (max-width: 991px) {
    width: auto;
    padding: 20px 5px;
  }
}

/* Carousel */
.ship-carousel::v-deep .el-carousel__container {
  height: 350px;

  img {
    width: 100%;
  }

  @media (max-width: 991px) {
    width: 100%;
    height: 35vw;
  }
}

/* Search Section */
.search-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  background: white;
  border: solid 1px #ddd;
  border-radius: 8px;

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.search-menu h3 {
  color: #000166;
}

.search-menu a {
  line-height: 50px;
}

/* Search Option */
.search-option::v-deep {
  width: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 991px) {
    margin-top: 10px;
    flex-direction: column;
  }
}

.search-field {
  /* width: 20%; */

  @media (max-width: 991px) {
    width: 100% !important;
    margin-bottom: 10px !important;
  }
}

.search-option button {
  width: 15%;
  margin-left: 5px;
  padding: 12px;
  color: #fff;
  font-size: 0.95em;
  font-weight: bold;
  background: #000166;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  @media (max-width: 991px) {
    width: 100%;
    margin-top: 10px;
  }
}

.search-option button i {
  padding-right: 5px;
}

/* Cruise List */
.cruise-list {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;
  flex-direction: row;

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.item {
  width: calc((100% / 4));

  @media (max-width: 991px) {
    width: 100%;
  }
}

/* Pagination */
.el-footer {
  background: #f5f5f5;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 20px 0;
  font-weight: bold;
}

.pagination button {
  padding: 12px;
  color: #fff;
  font-size: 0.95em;
  font-weight: bold;
  background: #000166;
  border: none;
  cursor: pointer;
}

.pagination button:disabled {
  color: #fff;
  background: #d9d9d9;
  border: none;
  cursor: context-menu;
}

.previous-button {
  margin-right: 5px;
}

.next-button {
  margin-left: 5px;
}

.pagination .page-button {
  margin: 0 5px 0 5px;
  padding: 12px;
  color: #fff;
  background: #d9d9d9;
  font-size: 0.95em;
  text-align: center;
  border: none;
  cursor: context-menu;
}

.pagination .disabled,
.pagination .page-button:hover {
  background: #000166;
}

/* Loading */
.loading-screen {
  width: 100%;
  height: 300px;
  text-align: center;
  padding-top: 50px;
}

.loading-screen i {
  color: #828282;
  font-size: 3em;

  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
