<template>
  <div class="content">
    <i class="fa-solid fa-circle-exclamation"></i>
    {{ $t("noInfo") }}
  </div>
</template>
<script>
export default {
  name: "App",
};
</script>
<style scoped>
.content {
  text-align: center;
  margin: 30px 0;
  font-size: 1.5em;

  @media (max-width: 991px) {
    margin: 50px 0;
    font-size: 1.3em;
  }
}
</style>
