<template>
  <div v-if="loading" class="loading-screen">
    <i class="fa-solid fa-spinner"></i>
  </div>
  <div v-else>
    <el-container>
      <!-- Header -->
      <el-header class="top-nav">
        <div class="container">
          <div>
            <h3 v-if="$route.query.tag">
              {{
                $route.query.tag
                  .split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")
              }}
            </h3>
            <h3
              v-if="
                !$route.query.tag &&
                ($route.query.keyword ||
                  $route.query.period ||
                  $route.query.province)
              "
            >
              {{ $t("searchTerm") }}: {{ $route.query.keyword }}
              {{ $route.query.period }}
              {{ $route.query.province }}
            </h3>
          </div>
          <div class="nav-title">
            <a class="router-link" style="margin-right: 5px"
              ><router-link class="nav-route" to="/">{{
                $t("homePage")
              }}</router-link></a
            >
            /
            <a style="margin-left: 5px"
              >{{
                !this.isInternational ? categoryLabel || $t("domestic") : ""
              }}
              {{
                this.isInternational ? categoryLabel || $t("international") : ""
              }}
              {{ $t("tours") }}</a
            >
          </div>
        </div>
      </el-header>
      <el-main class="main-content">
        <div class="container">
          <!-- Search Tour Panel -->
          <div class="search-box" v-if="isInternational">
            <div class="search-menu">
              <div class="search-option">
                <a>{{ $t("enterKeyword") }}</a>
                <el-input
                  v-model="keyword"
                  style="height: 32px; margin-top: 10px"
                  :placeholder="$t('enterKeyword')"
                />
              </div>
              <div class="search-option">
                <a>{{ $t("selectCityCountry") }}</a>
                <el-select
                  v-model="countryValue"
                  :placeholder="'--' + $t('selectCityCountry') + '--'"
                  style="margin-top: 10px"
                >
                  <el-option
                    v-for="item in countryOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </div>
              <div class="search-option">
                <!-- Date Picker -->
                <a>{{ $t("startDate") }}</a>
                <el-date-picker
                  style="margin-top: 10px"
                  v-model="startDate"
                  type="date"
                  :placeholder="$t('pickADay')"
                  format="YYYY/MM/DD"
                  value-format="x"
                  :disabled-date="disabledStartDate"
                  size="default"
                />
              </div>
              <div class="search-option">
                <a>{{ $t("endDate") }}</a>
                <el-date-picker
                  style="margin-top: 10px"
                  v-model="endDate"
                  type="date"
                  :placeholder="$t('pickADay')"
                  format="YYYY/MM/DD"
                  value-format="x"
                  :disabled-date="disabledEndDate"
                  size="default"
                />
              </div>
              <button @click="navigateToSearch()">
                <i class="fa-solid fa-magnifying-glass"></i>
                {{ $t("search") }}
              </button>
            </div>
            <!-- Price Slider -->
            <div class="search-price">
              <div class="price-slider-display">
                <h3>{{ priceSliderValue[0] }}</h3>
                <a>{{ $t("between") }}</a>
                <h3>{{ priceSliderValue[1] }}</h3>
              </div>
              <el-slider
                v-model="priceSliderValue"
                range
                :min="0"
                :max="300000"
              />
            </div>
          </div>
          <!-- Search Result -->
          <div class="searchResult">
            <a>{{ $t("found") }} {{ totalCount }} {{ $t("program") }}</a>
          </div>
          <!-- Result List -->
          <div class="package-list">
            <div
              class="item"
              v-for="(tourItem, index) in tourArray"
              :key="index"
            >
              <TourItem
                :id="tourItem.id"
                :code="tourItem.code"
                :travelBy="tourItem.travel_by"
                :title="tourItem.title"
                :images="tourItem.images"
                :numberOfDays="tourItem.number_of_days"
                :startDate="tourItem.start_date"
                :endDate="tourItem.end_date"
                :price="tourItem.price_per_person"
              />
            </div>
          </div>
        </div>
      </el-main>
      <el-footer>
        <!-- Pagination -->
        <div class="pagination">
          <button
            class="previous-button"
            @click="previousPage"
            :disabled="currentPage === 1"
          >
            {{ $t("previousPage") }}
          </button>
          <div v-for="(pageNumber, index) in visiblePages" :key="pageNumber">
            <a
              style="font-weight: bold"
              v-if="index !== 0 && visiblePages[index - 1] !== pageNumber - 1"
              >...</a
            >
            <span
              :class="
                'page-button' + [currentPage == pageNumber ? ' disabled' : '']
              "
              v-if="totalPages > 1"
              :key="pageNumber"
              @click="gotoPage(pageNumber)"
              :disabled="currentPage === pageNumber"
            >
              {{ pageNumber }}</span
            >
          </div>
          <span class="page-button disabled" v-if="totalPages < 2">1</span>
          <button
            class="next-button"
            @click="nextPage"
            :disabled="currentPage === totalPages || totalPages === 0"
          >
            {{ $t("nextPage") }}
          </button>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
import { getCurrentInstance } from "vue";
import TourItem from "../../components/item/tourItem.vue";

import axios from "axios";
import config from "../../api/config.json";

export default {
  name: "App",
  components: {
    TourItem,
  },
  data: function () {
    return {
      itemsPerPage: 8,
      currentPage: 1,
      totalCount: 0,

      loading: true,

      // 搜索栏 外国
      keyword: this.$route.query.keyword,
      countryValue: Number(this.$route.query.category) || 1,
      startDate: Number(this.$route.query.startDate),
      endDate: Number(this.$route.query.endDate),
      priceSliderValue: [
        Number(this.$route.query.startPrice) == ""
          ? Number(this.$route.query.startPrice)
          : 0,
        Number(this.$route.query.endPrice) == ""
          ? Number(this.$route.query.endPrice)
          : 35000,
      ],

      // 搜索栏 国内
      category: this.$route.query.category || "",

      tag: this.$route.query.tag || "",

      // 旅游方案
      tourArray: [],

      moment: getCurrentInstance().appContext.config.globalProperties.$moment,
    };
  },
  mounted() {
    this.fetchTourData();
  },
  computed: {
    /**
     *  从store获取国家列表
     */
    countryArray() {
      return this.$store.getters.getCountryList;
    },

    /**
     * 搜索设置 国家选项
     */
    countryOptions() {
      let optionList = [{ value: 1, label: "--Choose a country--" }];
      this.countryArray.forEach((country) => {
        optionList.push({
          value: country.id,
          label: country.title,
        });
      });
      return optionList;
    },

    /**
     * 获取 国家名字 或是 区域名字
     */
    categoryLabel() {
      return (
        this.countryArray.find((country) => {
          return country.id === Number(this.$route.query.category);
        })?.title ||
        this.regionArray.find((region) => {
          return region.id === Number(this.$route.query.category);
        })?.title
      );
    },

    /**
     * 检查是否是 外国旅游
     */
    isInternational() {
      return (
        this.countryArray.some(
          (country) => country.id === Number(this.$route.query.category)
        ) || Number(this.$route.query.category) === 1
      );
    },

    /**
     *  从store获取地区/州属列表
     */
    regionArray() {
      return this.$store.getters.getStateList;
    },

    /**
     * 获取该页面可显示的物件数量 (一个页面最多八个物件，以此类推)
     */
    // paginatedItems() {
    //   const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    //   const endIndex = startIndex + this.itemsPerPage;
    //   return this.tourArray.slice(startIndex, endIndex);
    // },

    /**
     * 获取计算后的页面总数
     */
    totalPages() {
      return Math.ceil(this.totalCount / this.itemsPerPage);
    },

    /**
     * 获取当前显示的页面号码
     */
    visiblePages() {
      const totalPages = this.totalPages;
      const currentPage = this.currentPage;

      let visiblePages = [];
      visiblePages.push(1);

      for (let i = 1; i <= totalPages; i++) {
        if (currentPage == i && !visiblePages.includes(i)) {
          visiblePages.push(i);
        } else if (i === totalPages && !visiblePages.includes(i)) {
          visiblePages.push(i);
        } else if (i === currentPage - 1 && !visiblePages.includes(i)) {
          visiblePages.push(i);
        } else if (i === currentPage + 1 && !visiblePages.includes(i)) {
          visiblePages.push(i);
        }
      }

      return visiblePages;
    },
  },
  watch: {
    countryArray() {
      if (this.isInternational) {
        document.title = `${
          this.countryArray.find((country) => country.id === this.countryValue)
            ?.title || this.$t("international")
        } ${this.$t("tours")}`;
      }
    },
    regionArray() {
      if (!this.isInternational) {
        document.title = `${
          this.regionArray.find((state) => state.id === this.provinceValue)
            ?.title || this.$t("domestic")
        } ${this.$t("tours")}`;
      }
    },
  },
  methods: {
    /**
     * 获取 旅游方案 数据
     */
    async fetchTourData() {
      this.loading = true;
      // 设置请求
      let queryParams = {};

      // 如果有标签，则只显示标签
      if (this.tag) {
        queryParams.tag = this.tag || "";
      } else {
        // 若没有标签，根据其旅游类型 请求API
        // 根据已设置的搜索条件加入 请求
        if (this.keyword && this.keyword != "")
          queryParams.keyword = this.keyword;

        if (this.startDate && this.startDate != "")
          queryParams.startDate = this.startDate / 1000;

        if (this.endDate && this.endDate != "")
          queryParams.endDate = this.endDate / 1000;

        queryParams.category = this.category || "";

        // 国外旅游
        if (this.isInternational) {
          queryParams.startPrice = this.priceSliderValue[0];
          queryParams.endPrice = this.priceSliderValue[1];
        }
      }

      queryParams.page = this.currentPage;
      queryParams.limit = this.itemsPerPage;

      // 获取旅游方案数据
      await axios
        .get(`${config.apiBaseUrl}/${config.version}/plan`, {
          params: queryParams,
        })
        .then((response) => {
          this.tourArray = response.data.data;
          this.totalCount = response.data.count;
        })
        .catch((error) => {
          // 发生错误
          console.error("Error fetching tours:", error);
        })
        .finally(() => {
          // 执行就结束，结束加载中
          this.loading = false;

          // 设置网址标签名称
          // * 国外旅游
          if (this.isInternational) {
            document.title = `${
              this.countryArray.find(
                (country) => country.id === this.countryValue
              )?.title || this.$t("international")
            } ${this.$t("tours")}`;
            // * 国内旅游
          } else {
            document.title = `${
              this.regionArray.find((state) => state.id === this.provinceValue)
                ?.title || this.$t("domestic")
            } ${this.$t("tours")}`;
          }
        });
    },

    /**
     * 搜索旅游配套
     */
    navigateToSearch() {
      console.log(
        "Number(this.$route.query.startPrice)",
        Number(this.$route.query.startPrice)
      );

      console.log("this.priceSliderValue", this.priceSliderValue);
      // 设置请求
      let queryParams = {};

      // 根据已设置的搜索条件加入 请求
      if (this.keyword && this.keyword != "")
        queryParams.keyword = this.keyword;

      if (this.startDate && this.startDate != "")
        queryParams.startDate = this.startDate / 1000;

      if (this.endDate && this.endDate != "")
        queryParams.endDate = this.endDate / 1000;

      queryParams.category = this.category || "";

      if (this.isInternational) {
        // 国外旅游
        queryParams.category = this.countryValue;
        queryParams.startPrice = this.priceSliderValue[0];
        queryParams.endPrice = this.priceSliderValue[1];
      }

      this.$router.push({
        path: "/tour_list/search",
        query: queryParams,
      });
      window.scrollTo(0, 0);
    },

    /**
     * 显示下个页面的物件
     */
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchTourData();
        window.scrollTo(0, 0);
      }
    },

    /**
     * 显示上个页面的物件
     */
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchTourData();
        window.scrollTo(0, 0);
      }
    },

    /**
     * 移动至选定页面
     */
    gotoPage(pageNumber) {
      this.currentPage = pageNumber;
      this.fetchTourData();
      window.scrollTo(0, 0);
    },

    /**
     * 选择开始时间时，
     * 禁用结束日期之后的时间
     */
    disabledStartDate(time) {
      if (this.endDate && typeof this.endDate === "number") {
        let endDateMoment = this.moment(this.endDate);
        let dayBeforeEndDate = endDateMoment.subtract(1, "days");

        return time.getTime() > dayBeforeEndDate;
      } else {
        // 若未选择任何结束时间，则不禁止任何时间
        return null;
      }
    },

    /**
     * 选择结束时间时，
     * 禁用开始日期之前的时间
     */
    disabledEndDate(time) {
      if (this.startDate && typeof this.startDate === "number") {
        let startDateMoment = this.moment(this.startDate);
        let dayBeAfterStartDate = startDateMoment.add(1, "days");

        return time.getTime() < dayBeAfterStartDate;
      } else {
        // 若未选择任何开始时间，则不禁止任何时间
        return null;
      }
    },
  },
};
</script>
<style scoped>
/* Top Nav */
.top-nav {
  display: block;
  width: 100%;
  height: 56px;
  background: #2d3e52;

  @media (max-width: 991px) {
    width: auto;
  }
}

.top-nav .container {
  display: flex;
  align-items: center;
  width: 1170px;
  height: 100%;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  color: #5a7ca3;
  font-weight: bold;

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 100%;
  }

  @media (max-width: 991px) {
    width: 100%;
  }
}

.top-nav a {
  color: #fdb714;
  font-size: 1em;
  text-decoration: none;
}

.top-nav h3 {
  color: #fff;
  font-weight: 300;
  text-decoration: none;
}

router-link {
  text-decoration: none;
}

.top-nav .router-link:hover {
  color: #12a0c3;
}

.nav-title {
  display: block;
  @media (max-width: 991px) {
    display: none;
  }
}

/* Body */
.main-content {
  display: block;
  width: 100%;
  background: #f5f5f5;

  @media (max-width: 991px) {
    width: auto;
  }
}

.main-content .container {
  width: 1170px;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 95%;
  }

  @media (max-width: 991px) {
    width: auto;
    padding: 20px 5px;
  }
}

/* Search Tour Panel */
.search-box {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  padding: 25px 0;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.15);
}

.search-menu {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0 15px;

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

/* Search Option */
.search-option {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5px;

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 19%;
    margin: 0 5px;
  }

  @media (max-width: 991px) {
    width: 100%;
    margin-top: 10px;
  }
}

.search-option::v-deep .el-date-editor {
  @media (min-width: 991px) and (max-width: 1260px) {
    width: 99%;
    margin: 0 5px;
  }

  @media (max-width: 991px) {
    width: 100%;
  }
}

.search-price {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  margin-top: 15px;

  @media (max-width: 991px) {
    width: 80%;
  }
}

.search-box h3 {
  width: 100%;
  margin: 0.4em 0 0.6em 0;
  text-align: center;
}

.search-box a {
  margin: 0;
  padding: 0;
  font-size: 1em;
  padding-left: 2px;
  color: #4d4c4c;
  font-weight: bold;
}

.price-slider-display {
  display: flex;
  width: 80%;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
}

.price-slider-display a {
  display: flex;
  align-items: center;
  font-weight: 100;
}

.search-menu button {
  width: 15%;
  height: 32px;
  padding: 5px;
  color: #fff;
  font-size: 0.95em;
  font-weight: bold;
  background: #000166;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  @media (max-width: 991px) {
    width: 100%;
    margin-top: 20px;
  }
}

.disabled-search {
  opacity: 0.6;
  cursor: wait !important;
}

.search-option button i {
  padding-right: 5px;
}

/* Search Result */
.searchResult {
  margin-bottom: 30px;
  padding-left: 30px;
  background: white;
  border: solid 1px #ddd;
  border-radius: 8px;
}

.searchResult a {
  line-height: 50px;
}

/* Package List */
.package-list {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;
  flex-direction: row;

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.item {
  width: calc((100% / 4));

  @media (max-width: 991px) {
    width: 100%;
  }
}

/* Pagination */
.el-footer {
  background: #f5f5f5;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 20px 0;
  font-weight: bold;
}

.pagination button {
  padding: 12px;
  color: #fff;
  font-size: 0.95em;
  font-weight: bold;
  background: #000166;
  border: none;
  cursor: pointer;
}

.pagination button:disabled {
  color: #fff;
  background: #d9d9d9;
  border: none;
  cursor: context-menu;
}

.previous-button {
  margin-right: 5px;
}

.next-button {
  margin-left: 5px;
}

.pagination .page-button {
  margin: 0 5px 0 5px;
  padding: 12px;
  color: #fff;
  background: #d9d9d9;
  font-size: 0.95em;
  text-align: center;
  border: none;
  cursor: context-menu;
}

.pagination .disabled,
.pagination .page-button:hover {
  background: #000166;
}

/* Loading */
.loading-screen {
  width: 100%;
  height: 300px;
  text-align: center;
  padding-top: 50px;

  i {
    font-size: 3em;
  }
}

.fa-spinner {
  color: #828282;
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
