<template>
  <div class="body">
    <!-- Similar Tour Package Program List -->
    <h3>{{ $t("similarPackage") }}</h3>
    <div v-if="state.loading" class="loading-screen">
      <i class="fa-solid fa-spinner"></i>
    </div>
    <div v-else class="package-display">
      <div
        class="item"
        v-for="(packageItem, index) in state.similarPackageArray"
        :key="index"
      >
        <PackageItem
          :code="packageItem.code"
          :id="packageItem.id"
          :travelBy="packageItem.travel_by"
          :title="packageItem.title"
          :images="packageItem.images"
          :duration="packageItem.number_of_days"
          :startDate="packageItem.start_date"
          :endDate="packageItem.end_date"
          :price="packageItem.price_per_person"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import PackageItem from "../../components/item/packageItem.vue";

import axios from "axios";
import config from "../../api/config.json";
import { onMounted, defineProps, reactive } from "vue";

const props = defineProps({
  id: String, // 旅游配套ID
  category: Number, // 旅游配套 分类/国家
});

const state = reactive({
  similarPackageArray: [],
  loading: true,
});

onMounted(() => {
  fetchPackageData();
});

/**
 * 获取 旅游配套 数据
 */
const fetchPackageData = async () => {
  // 显示加载中
  state.loading = true;

  // 获取旅游配套数据
  await axios
    .get(`${config.apiBaseUrl}/${config.version}/plan`, {
      params: { category: props.category },
    })
    .then((response) => {
      state.similarPackageArray = response.data.data.slice(0, 4);
    })
    .catch((error) => {
      // 发生错误
      console.error("Error fetching packages:", error);
    })
    .finally(() => {
      // 执行就结束，结束加载中
      state.loading = false;
    });
};
</script>
<style scoped>
/* Tour Package List */
.body h3 {
  width: 96%;
  margin-top: 10px;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 2px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.15);

  @media (max-width: 991px) {
    width: 85%;
  }
}

.package-display {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;
  flex-direction: row;

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.package-display .item {
  width: calc((100% / 4));

  @media (max-width: 991px) {
    width: 100%;
  }
}

/* Loading */
.loading-screen {
  width: 100%;
  height: 300px;
  text-align: center;
  padding-top: 50px;
}

.loading-screen i {
  color: #828282;
  font-size: 3em;

  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
