<template>
  <Transition name="chatBox">
    <div v-if="displayChat" class="container">
      <div class="social-media">
        <div class="section-1">
          <i class="fa-brands fa-line" alt="Share on Line"></i>
          {{ $t("bookViaLine") }}
        </div>
        <div class="section-2">
          <a>{{ $t("contactForNews") }}</a>
          <a :href="`https://line.me/R/ti/p/%40${line}`">
            <img src="../assets/line_qr_new_2.jpg" />
          </a>
          <a :href="`https://line.me/R/ti/p/%40${line}`">@Sunmoonthe</a>
        </div>
        <div class="section-3">
          <i class="fa-solid fa-phone" alt="Call Us"></i>
          <div class="section-3-1">
            <a href="tel:09-8570-8809">09-8570-8809</a>
            <a href="tel:09-8845-4210">09-8845-4210</a>
          </div>
        </div>
        <div class="section-4">{{ $t("mondayToFriday") }}</div>
        <div class="section-5">
          <!-- Facebook -->
          <a :href="`https://www.facebook.com/${facebook}`">
            <i
              class="fa-brands fa-facebook social-img"
              alt="Contact Us on Facebook"
            ></i>
          </a>
          <!-- Line -->
          <a :href="`https://line.me/R/ti/p/%40${line}`">
            <i class="fa-brands fa-line social-img" alt="Share on Line"></i>
          </a>
          <!-- Email -->
          <a :href="`mailto:${email}`">
            <i
              class="fa-regular fa-envelope social-img"
              alt="Share on Email"
            ></i>
          </a>
        </div>
      </div>
    </div>
  </Transition>
  <div
    class="button"
    @click="openChat = !openChat"
    :style="openChat ? 'background-color: #ff0000;' : ''"
  >
    <Transition name="chatOpen" @after-leave="afterLeave">
      <i v-if="openChat && displayChat" class="fa-solid fa-xmark"></i>
    </Transition>
    <Transition name="chatOff" @after-leave="afterLeave">
      <i v-if="!openChat && !displayChat" class="fa-regular fa-message"></i>
    </Transition>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

// const phone = ref("09-8570-8809");

const openChat = ref(true);
const displayChat = ref(true);

const afterLeave = () => {
  displayChat.value = openChat.value;
};

const facebook = computed(() => {
  return store.getters.getCompanyFacebookLink;
  // return "sunmoontourandtravel";
});

const line = computed(() => {
  return store.getters.getCompanyLineId;
  // return "gtq9153t";
});

const email = computed(() => {
  return store.getters.getCompanyEmail;
  // return "special.sunmoon@gmail.com";
});
</script>
<style scoped>
.button {
  position: fixed;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 2%;
  bottom: 3%;
  color: white;
  background-color: #00c300;
  transition: background-color 0.35s;
  border-radius: 50px;
  z-index: 2;

  @media (max-width: 500px) {
    display: none;
  }
}

.button i {
  font-size: 1.2em;
}

.chatOff-enter-from,
.chatOff-leave-to {
  transform: rotate(24deg);
}

.chatOff-enter-active,
.chatOff-leave-active {
  transition: transform 0.1s;
}

.chatOpen-enter-from,
.chatOpen-leave-to {
  transform: rotate(-30deg);
}

.chatOpen-enter-active,
.chatOpen-leave-active {
  transition: transform 0.1s;
}

.container {
  position: fixed;
  width: 210px;
  height: auto;
  right: 2%;
  bottom: 10%;
  color: white;
  background-color: #00c300;
  overflow: hidden;
  border-radius: 15px;
  z-index: 2;

  @media (max-width: 500px) {
    display: none;
  }
}

/* Animation */
.chatBox-enter-active {
  animation: bounceAway 0.3s ease-in-out reverse;
}

.chatBox-leave-to {
  animation: bounceAway 0.3s ease-in-out;
}

@keyframes bounceAway {
  0% {
    transform: translateX(0);
  }
  15% {
    transform: translateX(-10px);
  }
  30% {
    transform: translateX(-20px);
  }
  45% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(150%);
  }
}

.social-media {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px 0 0 0;
}

.social-img {
  padding: 4px;
  margin: 6px 5px;
  color: white;
  font-size: 16px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.fa-facebook {
  background: #4267b2;
}

.fa-facebook:hover {
  background: #3a5a9b;
}

.fa-line {
  background: #06c755;
}

.fa-line:hover {
  background: #06b54f;
}

.fa-phone {
  background: rgba(255, 255, 255, 0);
}

.fa-envelope {
  background: rgb(199, 41, 41);
}

.fa-envelope:hover {
  background: rgb(187, 39, 39);
}

/* Section 1 */
.section-1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 10px 0;
}

.section-1 .fa-line {
  color: white;
  font-size: 20px;
  margin-right: 5px;
}

/* Section 2 */
.section-2 {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  font-size: 12px;
  white-space: nowrap;
}

.section-2 img {
  width: 110px;
  border-radius: 5px;
  margin: 10px 0;
}

/* Section 3 */
.section-3 {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 0;
  border-top: solid 1px rgba(255, 255, 255, 0.4);
}

.section-3 i {
  color: white;
  font-size: 25px;
}
.section-3-1 {
  display: flex;
  flex-direction: column;
  line-height: 25px;
  font-size: 13px;
}

/* Section 4 */
.section-4 {
  width: 100%;
  padding: 10px 0;
  text-align: center;
  font-size: 13px;
  border-top: solid 1px rgba(255, 255, 255, 0.4);
}

/* Section 5 */
.section-5 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5px 0;
  background: #1f7c1d;
}

a {
  text-decoration: none;
  color: white;
}
</style>
