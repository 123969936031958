<template>
  <div class="ticket-item" @click="navigateToDetails()">
    <div class="container">
      <!-- Image -->
      <div class="ticket-image">
        <img
          :src="imageUrl || require('../../assets/no-image.svg')"
          :alt="name"
          @error="$event.target.src = require('../../assets/no-image.svg')"
        />
      </div>
      <!-- Title -->
      <h3>{{ name }}</h3>
      <!-- Ticket Info -->
      <div class="ticket-info">
        <i class="fa-solid fa-tags"></i><a>{{ id }}</a>
        <a style="padding: 0 10px 0 10px; font-weight: bold"> | </a>
        <i class="fa-solid fa-location-dot"></i><a>{{ countryName }}</a>
      </div>
      <div class="price-section">
        <div class="ticket-status">
          <a>{{ $t("bookToday") }}</a>
        </div>
        <div class="ticket-price">
          <h1>{{ formattedPrice }}</h1>
          <a>{{ $t("baht") }}</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { defineProps, computed } from "vue";

const router = useRouter();

const props = defineProps({
  page: String, // 景点入门票Page
  id: String, // 入门票ID
  name: String, // 景点名称
  countryName: String, // 景点国家
  imageUrl: String, // 预览图
  price: Number, // 价钱
});
/*
 * 将价钱格式化(加逗号)
 */
const formattedPrice = computed(() => {
  return props.price.toLocaleString("en-US");
});
/**
 * 进入 入门票资讯 界面
 */
const navigateToDetails = () => {
  router.push({
    path: "/ticket",
    query: {
      page: props.page,
    },
  });
  window.scrollTo(0, 0);
};
</script>
<style scoped>
/* Body */
.ticket-item {
  position: relative;
  width: 93%;
  height: auto;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 2px;
  border: 1px solid #ffffff;
  margin-right: 12px;
  margin-bottom: 12px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.15);
  cursor: pointer;

  &:hover {
    .container {
      border-color: #12a0c3;
      transition: border-color 0.5s;
    }
    h3 {
      color: #12a0c3;
      transition: color 0.5s;
    }
  }

  @media (max-width: 991px) {
    width: 98%;
    margin-right: 0;
  }
}

.container {
  border: 2px solid #fff;
  border-radius: 6px;
  transition: border-color 0.5s;
}

/* Ticket Image */
.ticket-image img {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 4px;
  object-fit: cover;
}

/* Ticket Name */
h3 {
  max-width: 100%;
  height: 2.3rem;
  margin: 10px 5px;
  font-size: 0.9em;
  text-align: center;
  color: #4d4c4c;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  transition: color 0.5s;
}

/* Ticket Info */
.ticket-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  margin: 0 10px;
  border-top: solid 1px #ddd;
  border-bottom: solid 1px #ddd;
}

.ticket-info div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 3px 0 3px 0;
}

.ticket-info i {
  color: #fdb714;
  padding-right: 5px;
}

.ticket-info a {
  font-size: 0.8em;
  color: #6b6b6b;
}

/* Price */
.price-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 15px;
}

.ticket-status {
  display: flex;
  align-items: center;
  width: 50%;
}

.ticket-status a {
  color: #00b60b;
  font-size: 0.9em;
}

.ticket-price {
  display: flex;
  align-items: baseline;
  font-size: 0.9em;
  color: #6b6b6b;
}

.ticket-price h1 {
  margin: 5px 5px 0 0;
  font-size: 1.8em;
  color: #ec1d24;
}
</style>
