<template>
  <div class="article-item">
    <div class="top-tag">
      <i class="fa-solid fa-location-dot"></i>{{ country }}
    </div>
    <div class="container" @click="navigateToDetail()">
      <!-- Image -->
      <div class="article-image">
        <img
          :src="imageUrl || require('../../assets/no-image.svg')"
          @error="$event.target.src = require('../../assets/no-image.svg')"
        />
      </div>
      <!-- Info -->
      <div class="article-info">
        <h3>{{ title }}</h3>
        <p>{{ description }}</p>
        <div class="button">
          <a class="read"
            ><i class="fa-brands fa-readme"></i>{{ $t("read") }}</a
          >
        </div>
      </div>
    </div>
    <a class="share"
      ><i class="fa-solid fa-share-from-square"></i>{{ $t("share") }}</a
    >
    <div class="share-media-list">
      <!-- Facebook -->
      <a
        :href="`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          currentLink
        )}&display=popup&ref=plugin&src=like`"
      >
        <i class="fa-brands fa-facebook social-img" alt="Share on Facebook"></i>
      </a>
      <!-- Messenger -->
      <a
        :href="`https://www.messenger.com/sharer.php?u=${encodeURIComponent(
          currentLink
        )}`"
      >
        <i
          class="fa-brands fa-facebook-messenger social-img"
          alt="Share on Messenger"
        ></i>
      </a>
      <!-- Line -->
      <a
        :href="`https://line.me/R/msg/text/?${encodeURIComponent(currentLink)}`"
      >
        <i class="fa-brands fa-line social-img" alt="Share on Line"></i>
      </a>
      <!-- Email -->
      <a :href="`mailto:?subject=&body=${encodeURIComponent(currentLink)}`">
        <i class="fa-regular fa-envelope social-img" alt="Share on Email"></i>
      </a>
      <!-- Twitter -->
      <a
        :href="`https://twitter.com/intent/tweet?url=${encodeURIComponent(
          currentLink
        )}`"
      >
        <i class="fa-brands fa-x-twitter social-img" alt="Share on Twitter"></i>
      </a>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { defineProps } from "vue";

const router = useRouter();

const props = defineProps({
  articleId: String,
  title: String,
  description: Number,
  country: String,
  imageUrl: String,
});

/**
 * 获取分享用的网址（即当前网址+点击的该文章）
 */
const currentLink = router.resolve({
  path: "/article_info",
  query: {
    id: props.articleId,
  },
}).href;

/**
 * 进入 旅游文章 内容界面
 */
const navigateToDetail = () => {
  router.push({
    path: "/article_info",
    query: {
      id: props.articleId,
    },
  });
};
</script>

<style scoped>
/* Body */
.article-item {
  position: relative;
  width: 93%;
  height: auto;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 2px;
  border: 1px solid #ffffff;
  margin-right: 12px;
  margin-bottom: 12px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.15);
  cursor: pointer;

  &:hover {
    .container {
      border-color: #12a0c3;
      transition: border-color 0.5s;
    }
    h3 {
      color: #12a0c3;
      transition: color 0.5s;
    }
    img {
      opacity: 0.7;
      transform: scale(1.3);
      transition: opacity 0.5s, transform 0.5s;
    }

    .button .read {
      color: #278de8;
      transition: color 0.5s;
    }
  }

  @media (max-width: 991px) {
    width: 99%;
    margin-right: 0;
  }
}

.top-tag {
  position: absolute;
  width: auto;
  margin: 10px 0 0 -5px;
  padding: 8px 15px;
  color: white;
  font-size: 1em;
  font-weight: bold;
  background: #613f49;
  border-radius: 0 5px 5px 0;
  z-index: 2;

  i {
    padding-right: 5px;
  }
}

.container {
  display: flex;
  height: 230px;
  border: 2px solid #fff;
  border-radius: 6px;
  transition: border-color 0.5s;

  @media (max-width: 991px) {
    flex-direction: column;
    height: 380px;
  }
}

/* Tour Image */
.article-image {
  width: 25%;
  overflow: hidden;

  @media (max-width: 991px) {
    width: 100%;
  }
}

.article-image img {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 4px;
  object-fit: contain;
  transform: scale(1.1);
  transition: opacity 0.5s, transform 0.5s;
}

/* Article Info */
.article-info {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 15px;

  @media (max-width: 991px) {
    width: 90%;
  }
}

.article-info h3 {
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.article-info p {
  width: 95%;
  margin: 0;
  padding: 10px 0;
  border-top: 1px solid #d5d5d5;
  border-bottom: 1px solid #d5d5d5;
  overflow: hidden;
  text-indent: 40px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  line-height: 30px;

  @media (max-width: 991px) {
    text-indent: 25px;
    padding-bottom: 8px;
  }
}

.button {
  width: 95%;
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
}

.button i {
  padding-right: 5px;
}

.button .read {
  padding: 7px;
  color: #2069ab;
  font-weight: bold;
  transition: color 0.5s;
}

/* Share */
.share {
  position: absolute;
  padding: 7px;
  top: 80%;
  right: 4%;
  color: #2069ab;
  background: white;
  font-size: 0.9em;
  font-weight: bold;
  border: solid 2px #2069ab;
  border-radius: 5px;
  cursor: pointer;

  @media (max-width: 991px) {
    top: 85%;
    right: 7%;
  }
}

.share:hover {
  color: #278de8;
  background: rgb(245, 245, 245);
  border-color: #278de8;

  ~ .share-media-list {
    display: block;
  }
}

/* Media Icon */
.share-media-list {
  display: none;
  position: absolute;
  top: 56%;
  right: 4%;
  padding: 10px;
  background: white;
  border: 1px solid rgb(231, 231, 231);

  @media (max-width: 991px) {
    top: 70%;
    right: 7%;
  }
}

.share-media-list:hover {
  display: block;
}

.social-img {
  padding: 6px;
  margin: 0 3px;
  color: white;
  font-size: 24px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}

.fa-facebook {
  background: #4267b2;
}

.fa-facebook:hover {
  background: #3a5a9b;
}

.fa-facebook-messenger {
  background: #168aff;
}

.fa-facebook-messenger:hover {
  background: #1582ef;
}

.fa-line {
  background: #06c755;
}

.fa-line:hover {
  background: #06b54f;
}

.fa-envelope {
  background: rgb(199, 41, 41);
}

.fa-envelope:hover {
  background: rgb(187, 39, 39);
}

.fa-x-twitter {
  background: #000000;
}

.fa-x-twitter:hover {
  background: #000000b7;
}
</style>
