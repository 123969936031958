<template>
  <div>
    <el-main class="main-content">
      <div class="container">
        <div
          class="content"
          :style="panelType === 1 ? 'height: 550px;' : 'height:650px;'"
        >
          <!-- Tab Options -->
          <div class="tab-options">
            <a
              :class="panelType === 1 ? 'toggled' : ''"
              @click="panelType = 1"
              >{{ $t("login") }}</a
            >
            <a
              :class="panelType === 2 ? 'toggled' : ''"
              @click="panelType = 2"
              >{{ $t("register") }}</a
            >
          </div>
          <div class="content-panel">
            <!-- Login -->
            <Transition name="fade" @after-leave="afterLeave">
              <LoginPanel
                v-if="panelType === 1 && displayPanel === 1"
              ></LoginPanel>
            </Transition>
            <!-- Register -->
            <Transition name="fade" @after-leave="afterLeave">
              <RegisterPanel
                v-if="panelType === 2 && displayPanel === 2"
              ></RegisterPanel>
            </Transition>
          </div>
        </div>
      </div>
    </el-main>
  </div>
</template>
<script setup>
import { ref } from "vue";
import LoginPanel from "../components/login/loginPanel.vue";
import RegisterPanel from "../components/login/registerPanel.vue";

const panelType = ref(1);
const displayPanel = ref(1);

const afterLeave = () => {
  displayPanel.value = panelType.value;
};
</script>
<style scoped>
.main-content {
  display: block;
  width: 100%;
  padding: 0px;
  background: #f5f5f5;

  @media (max-width: 991px) {
    width: auto;
  }
}

.main-content .container {
  width: 1170px;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 95%;
  }

  @media (max-width: 991px) {
    width: auto;
    padding: 20px 5px;
  }
}

.content {
  width: 400px;
  height: 550px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  border-radius: 2px;
  margin: 40px auto;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  transition: height 0.3s ease-in-out;

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

/* Tab Option */
.tab-options {
  width: 60%;
  height: auto;
  padding: 20px 0;
  display: flex;
  justify-content: space-evenly;
}

.tab-options a {
  height: 25px;
  padding: 0 10px 3px 10px;
  font-size: 1.2em;
  font-weight: bold;
  color: rgb(159, 159, 159);
  cursor: pointer;
  transition: color 0.1s ease, border 0.1s;
}

.toggled {
  color: black !important;
  border-bottom: black 2px solid;
}

/* Content Panel */
.content-panel {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-top: 5px;
}

/* Animation */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
