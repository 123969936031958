<template>
  <div class="form">
    <!-- action="http://103.197.158.7:81/api/v1/customer/login"
      method="post" -->
    <el-form
      @submit.prevent="submitForm"
      ref="loginFormRef"
      :model="form"
      :rules="rules"
    >
      <!-- Username -->
      <el-form-item class="input-box" :label="t('username')" prop="user">
        <el-input
          v-model="form.user"
          name="username"
          :placeholder="t('enterUsername')"
        />
      </el-form-item>
      <!-- Password -->
      <el-form-item class="input-box" :label="t('password')" prop="password">
        <el-input
          name="password"
          v-model="form.password"
          type="password"
          show-password
          :placeholder="t('enterPassword')"
        />
      </el-form-item>
      <el-form-item class="input-box" :label="t('captcha')" prop="captcha">
        <img :src="captchaImage" style="width: 300px" />
        <div class="captcha-input">
          <el-input
            v-model="form.captcha"
            name="captcha"
            :placeholder="t('enterCaptcha')"
          />
          <el-button class="button" type="primary" @click="reloadCaptcha"
            ><i class="fa-solid fa-arrows-rotate"></i>
          </el-button>
        </div>
      </el-form-item>
      <!-- Login Button -->
      <el-form-item class="button-list">
        <el-button
          class="button"
          :disabled="loginLoading"
          type="primary"
          native-type="submit"
          >{{ $t("login") }}
          <i
            class="fa-solid fa-spinner"
            :style="loginLoading ? '' : 'display: none;'"
          ></i
        ></el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<!-- native-type="submit" -->
<script setup>
import axios from "axios";
import config from "../../api/config.json";

import { ref, reactive } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

const router = useRouter();
const store = useStore();
const { t } = useI18n({ useScope: "global" });

// 登录表单
const form = reactive({
  user: "",
  password: "",
  captcha: "",
});

const loginFormRef = ref(null);

// 登录显示加载中
const loginLoading = ref(false);

// 验证码图片网址
const captchaImage = ref(
  `${config.apiBaseUrl}/${config.version}/customer/captcha`
);

/**
 * 检查用户名称是否为空
 * @param rule
 * @param value
 * @param callback
 */
const validateUsername = (rule, value, callback) => {
  if (!value) {
    callback(new Error(`${t("pleaseEnterUsername")}`));
  } else {
    callback();
  }
};

/**
 * 检查密码是否为空
 * @param rule
 * @param value
 * @param callback
 */
const validatePassword = (rule, value, callback) => {
  if (!value) {
    callback(new Error(`${t("pleaseEnterPassword")}`));
  } else {
    callback();
  }
};

/**
 * 检查验证码是否为空
 * @param rule
 * @param value
 * @param callback
 */
const validateCaptcha = (rule, value, callback) => {
  if (!value) {
    callback(new Error(`${t("pleaseEnterCaptcha")}`));
  } else {
    callback();
  }
};

/**
 * 验证登录规则
 */
const rules = {
  user: [
    { message: "Please enter a username", trigger: "blur" },
    { validator: validateUsername, trigger: "blur" },
  ],
  password: [
    { message: "Please enter a password", trigger: "blur" },
    { validator: validatePassword, trigger: "blur" },
  ],
  captcha: [
    { message: "Please enter captcha", trigger: "blur" },
    { validator: validateCaptcha, trigger: "blur" },
  ],
};

/**
 * 刷新验证码图片
 */
const reloadCaptcha = () => {
  captchaImage.value = `${config.apiBaseUrl}/${
    config.version
  }/customer/captcha?id=${Math.random()}`;
};

/**
 * 登录
 */
const submitForm = async () => {
  if (!loginFormRef.value) return;

  // 检测输入是否合格
  loginFormRef.value.validate(async (valid) => {
    if (valid) {
      // 合格
      // const formData = new FormData();
      // formData.append("username", form.user);
      // formData.append("password", form.password);
      // formData.append("captcha", form.captcha);

      await axios
        .post(
          `${config.apiBaseUrl}/${config.version}/customer/login`,
          {
            username: form.user,
            password: form.password,
            captcha: form.captcha,
          },

          // formData,
          {
            // headers: {
            //   "Content-Type": "multipart/form-data",
            // },
            withCredentials: true,
          }
        )
        .then((response) => {
          const { id, username, phone, token } = response.data.data;
          store.dispatch("login", {
            id,
            username,
            phone,
            token,
          });

          // 弹出登录成功讯息
          window.alert(t("loginSuccess"));

          router.push({ path: "/" });
        })
        .catch((error) => {
          // 发生错误
          console.error("Login Error:", error);

          // 弹出登录失败讯息
          let errorMessage = error.response.data.data;
          let message = `${t("loginFail")}`;
          let firstErrorMessage = errorMessage[Object.keys(errorMessage)[0]];

          if (firstErrorMessage) {
            message += `: ${firstErrorMessage}`;
          }
          window.alert(message);
        })
        .finally(() => {
          loginLoading.value = false;
        });
    } else {
      // 不合格
      loginLoading.value = false;
    }
  });
};
</script>
<style scoped>
.form {
  width: 75%;
  height: 100%;
  align-items: center;
  margin: 0 auto;
}

/* Input Box */
.input-box {
  flex-direction: column !important;
}

.input-box::v-deep .el-form-item__label {
  justify-content: flex-start;
}

/* Button List */
.button-list {
  margin-top: 20px;
}

.button-list::v-deep .el-form-item__content {
  flex-direction: column;
}

.button-list::v-deep .button {
  width: 100%;
  margin: 10px 0 10px 0;
}

.fa-spinner {
  margin-left: 10px;
  color: #828282;
  animation: rotate 1s linear infinite;
}

.captcha-input {
  width: 100%;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
}
.captcha-input::v-deep .el-input {
  width: 83%;
}
</style>
