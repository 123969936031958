import { createRouter, createWebHistory } from "vue-router";
import i18n from "../locales/i18n.js";
import store from "../store/store.js";

import Home from "../pages/home/home.vue";
import Login from "../pages/loginPage.vue";

import MyAccount from "../pages/account/myAccount.vue";
import UserProfile from "../pages/account/userProfile.vue";
import ResetPassword from "../pages/account/resetPassword.vue";
import MyOrder from "../pages/account/checkOrder.vue";
import OrderDetail from "../pages/account/orderDetail.vue";

import PlaneTicket from "../pages/planeTicket.vue";

import DomesticTour from "../pages/tour/domesticTour.vue";
import TourList from "../pages/tour/tourList.vue";
import TourDetail from "../pages/tour/tourDetail.vue";

import PackageList from "../pages/tour/packageList.vue";
import PackageDetail from "../pages/tour/packageDetail.vue";

import AdmissionTicket from "../pages/ticket/admissionTicket.vue";
import TicketDetail from "../pages/ticket/ticketDetail.vue";

import Cruise from "../pages/cruise/cruise.vue";
import CruiseDetail from "../pages/cruise/cruiseDetail.vue";

import CarRental from "../pages/car/carRental.vue";
import CarDetail from "../pages/car/carDetail.vue";

import ArticleList from "../pages/articleList.vue";
import ArticleDetail from "../pages/articleDetail.vue";

import AboutUs from "../pages/aboutUs.vue";
import PastWork from "../pages/pastWork.vue";
import ContactUs from "../pages/contactUs.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { title: store.getters.getSiteName || i18n.global.t("loading") },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { title: i18n.global.t("login") },
  },
  {
    path: "/account",
    name: "My Account",
    component: MyAccount,
    children: [
      {
        path: "/account/profile",
        name: "User Profile",
        component: UserProfile,
        meta: { title: i18n.global.t("myAccount") },
      },
      {
        path: "/account/reset_password",
        name: "Reset Password",
        component: ResetPassword,
        meta: { title: i18n.global.t("resetPassword") },
      },
      {
        path: "/account/orders",
        name: "My Order",
        component: MyOrder,
        meta: { title: i18n.global.t("myOrder") },
      },
      {
        path: "/account/order",
        name: "Order Detail",
        component: OrderDetail,
        meta: { title: i18n.global.t("myOrder") },
      },
    ],
  },
  {
    path: "/domestic_tour",
    name: "Domestic Tour",
    component: DomesticTour,
    meta: { title: i18n.global.t("domesticTours") },
  },
  {
    path: "/domestic_tour/search",
    name: "Domestic Tour List",
    component: TourList,
    meta: { title: i18n.global.t("domesticTours") },
  },
  {
    path: "/tour_list/search",
    name: "Tour List",
    component: TourList,
    meta: { title: i18n.global.t("internationalTours") },
  },
  {
    path: "/tour/:id",
    name: "Tour Detail",
    component: TourDetail,
  },
  {
    path: "/package_tour",
    name: "Tour Package",
    component: PackageList,
    meta: { title: i18n.global.t("tourPackage") },
  },
  {
    path: "/package/:id",
    name: "Package Detail",
    component: PackageDetail,
  },
  {
    path: "/airline_ticket",
    name: "Airline ticket booking service for all routes Friendly prices, good service, attention to quality.",
    component: PlaneTicket,
    meta: { title: i18n.global.t("bookFlightTicket") },
  },
  {
    path: "/admission_ticket",
    name: "Admission Ticket",
    component: AdmissionTicket,
    meta: { title: i18n.global.t("admissionTicket") },
  },
  {
    path: "/ticket",
    name: "Ticket Detail",
    component: TicketDetail,
    props: (route) => ({
      page: route.query.page,
    }),
  },
  {
    path: "/cruise_list",
    name: "Cruise",
    component: Cruise,
    meta: { title: i18n.global.t("cruiseShip") },
  },
  {
    path: "/cruise/:id",
    name: "Cruise Detail",
    component: CruiseDetail,
  },
  {
    path: "/car_rental",
    name: "Car Rental",
    component: CarRental,
    meta: { title: i18n.global.t("carRental") },
  },
  {
    path: "/car/:id",
    name: "Car Detail",
    component: CarDetail,
  },
  {
    path: "/about_us",
    name: "About Us",
    component: AboutUs,
    meta: { title: i18n.global.t("aboutUs") },
  },
  {
    path: "/contact_us",
    name: "Contact Us",
    component: ContactUs,
    meta: { title: i18n.global.t("contactUs") },
  },
  {
    path: "/article",
    name: "Travel Article",
    component: ArticleList,
    meta: { title: i18n.global.t("travelArticle") },
  },
  {
    path: "/article_info",
    name: "Article",
    component: ArticleDetail,
    props: (route) => ({
      id: route.query.id,
    }),
  },
  {
    path: "/past_work",
    name: "Past Work",
    component: PastWork,
    meta: { title: i18n.global.t("pastWork") },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || i18n.global.t("loading"); // Update document title based on route's meta title
  next();
});

export default router;
