<template>
  <div class="order-card">
    <div class="order-item" @click="navigateToOrderDetail()">
      <div class="info-panel">
        <div class="title-section">
          <h3 class="title">{{ travel_plan }}</h3>
          <div class="title-info">
            <a class="order-id"
              ><i class="fa-solid fa-clipboard-list"></i>
              {{ props.order_id }}</a
            >
            <a class="order-date"
              ><i class="fa-regular fa-clock"></i>
              {{ formatDate(order_date) }}</a
            >
          </div>
        </div>
        <a class="travel-date"
          ><i class="fa-regular fa-calendar-days"></i>
          {{ formatDate(start_date) }} - {{ formatDate(end_date) }}</a
        >
        <a class="price"
          ><i class="fa-solid fa-dollar-sign"></i> {{ total_price }} Baht</a
        >
      </div>
      <div class="status-panel">
        <h2 class="order-status" :style="{ color: statusColor }">
          {{ statusList[order_status - 1] }}
        </h2>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, defineProps, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

const moment = getCurrentInstance().appContext.config.globalProperties.$moment;

const router = useRouter();
const { t } = useI18n({ useScope: global });

const props = defineProps({
  order_id: Number,
  ticket_type: Number,
  travel_plan: String,
  order_date: Number,
  start_date: Number,
  end_date: Number,
  number_of_adults: Number,
  number_of_children: Number,
  order_status: Number,
  total_price: String,
});

const statusList = ref([
  t("paymentPending"),
  t("paymentSettled"),
  t("ticketIssued"),
]);

const statusColor = computed(() => {
  switch (props.order_status) {
    case 1:
      return "rgb(148, 130, 130)";
    case 2:
      return "rgb(93, 196, 93)";
    case 3:
      return "rgb(168, 176, 168)";
    default:
      return "black";
  }
});

/**
 * 显示文字版的日期
 */
const formatDate = (date) => {
  return moment(date * 1000).format("YYYY/MM/DD");
};

/**
 * 转移至 订单界面 界面
 */
const navigateToOrderDetail = () => {
  router.push({
    path: `/account/order`,
    query: { id: props.order_id },
  });
};
</script>

<style scoped>
/* Container */
.order-card {
  width: 98%;
  height: auto;
  margin: 0;
  padding: 0 !important;
}

/* Order Item */
.order-item {
  position: relative;
  display: flex;
  padding: 13px 40px;
  margin: 25px 0;
  background: rgb(228, 241, 252);
  border-radius: 10px;
  transition: background-color 0.3s ease;
  cursor: pointer;

  @media (max-width: 760px) {
    flex-direction: column;
  }
}

.order-item:hover {
  background: rgb(211, 228, 247);

  .title {
    color: rgb(0, 117, 207);
  }
}

.order-item::before {
  content: "";
  position: absolute;
  margin: auto 0;
  top: 43%;
  left: -15px;
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 100px;
}

.order-item::after {
  content: "";
  position: absolute;
  margin: auto 0;
  top: 43%;
  right: -15px;
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 100px;
}

/* Panel */
.info-panel {
  width: 70%;
  display: flex;
  flex-direction: column;

  @media (max-width: 760px) {
    width: 100%;
    margin-top: 10px;
  }
}

.status-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28%;
  margin-left: 15px;
  border-left: 2px dashed rgba(128, 128, 128, 0.318);

  @media (max-width: 760px) {
    width: 100%;
    margin: 15px 0 0 0;
    border-left: none;
    border-top: 2px dashed rgba(128, 128, 128, 0.318);
  }
}

.order-status {
  padding-left: 15px;
  text-align: center;
  white-space: rap;

  @media (max-width: 760px) {
    margin: 13px 0;
    padding-left: 0;
    font-size: 1.3em;
  }
}

/* Title */
.title-section {
  width: 100%;
  margin-bottom: 10px;
}

.title {
  margin: 5px 0 2px 0;
  transition: color 0.3s ease;
}

.title-info {
  color: rgb(134, 164, 186);
  font-size: 0.9em;
  font-weight: bold;
  .order-id,
  .ticket-type {
    margin-right: 25px;
  }
}

/* Travel Date */
.travel-date {
  margin: 5px 0;
  font-weight: bold;

  i {
    color: rgb(187, 187, 21);
  }
}

/* Travel Date */
.price {
  margin: 5px 0;
  font-size: 1.2em;
  font-weight: bold;
  color: rgb(218, 176, 8);
}
</style>
