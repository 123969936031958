<template>
  <div class="form">
    <el-form ref="registerFormRef" :model="form" :rules="rules">
      <!-- Username -->
      <el-form-item class="input-box" :label="t('username')" prop="user">
        <el-input v-model="form.user" :placeholder="t('enterUsername')" />
      </el-form-item>
      <!-- Password -->
      <el-form-item class="input-box" :label="t('password')" prop="password">
        <el-input
          v-model="form.password"
          type="password"
          show-password
          :placeholder="t('enterPassword')"
        ></el-input>
      </el-form-item>
      <!-- Phone Number -->
      <el-form-item class="input-box" :label="t('phoneNumber')" prop="phone">
        <MazPhoneNumberInput
          class="phone-input-field"
          v-model="phoneNumber"
          v-model:country-code="countryCode"
          show-code-on-list
          country-selector-display-name
          :country-locale="locale"
          :translations="{
            countrySelector: {
              placeholder: t('country'),
            },
            phoneInput: {
              example: t('example'),
            },
          }"
          :preferred-countries="['TH']"
          style="width: 100%"
          orientation="col"
          @update="results = $event"
        >
          <!-- Show Country Image -->
          <template #selector-flag="{ countryCode }">
            <img
              style="width: 20px"
              :src="`http://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`"
            />
          </template>
          <!-- Show Country -->
          <template #country-list-flag="{ countryCode }">
            <MazBadge
              size="0.8rem"
              style="
                margin-right: 10px;
                width: 26px;
                display: flex;
                justify-content: center;
              "
            >
              <img
                style="width: 40px"
                :src="`http://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`"
              />
            </MazBadge>
          </template>
        </MazPhoneNumberInput>
      </el-form-item>
      <!-- Verification Code -->
      <!-- <el-form-item class="input-box" label="Verification Code" prop="code">
        <el-input v-model="form.code" placeholder="Enter verification code">
          Request Button
          <template #append
            ><el-button
              :class="codeRequest ? 'disabled' : ''"
              :disabled="codeRequest"
              class="code-button"
              type="primary"
              @click="onSubmit"
              >Request Code</el-button
            ></template
          >
        </el-input>
      </el-form-item> -->
      <!-- Captcha -->
      <!-- <el-form-item class="input-box" label="Captcha" prop="captcha">
        <img :src="captchaImage" style="width: 300px" />
        <div class="captcha-input">
          <el-input v-model="form.captcha" placeholder="Enter captcha" />
          <el-button class="button" type="primary" @click="reloadCaptcha"
            ><i class="fa-solid fa-arrows-rotate"></i>
          </el-button>
        </div>
      </el-form-item> -->
      <!-- Button -->
      <el-form-item class="button-list">
        <el-button
          class="button"
          :disabled="registerLoading"
          type="primary"
          @click="submitForm()"
          >{{ $t("register") }}
          <i
            class="fa-solid fa-spinner"
            :style="registerLoading ? '' : 'display: none;'"
          ></i
        ></el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script setup>
import axios from "axios";
import config from "../../api/config.json";
import { reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";

const { t } = useI18n({ useScope: "global" });

// 手机号码输入表单
const phoneNumber = ref();
const countryCode = ref("TH");
const results = ref();

// const locale = localStorage.getItem("locale") || "en";
const locale = "en";

// 注册表单
const form = reactive({
  user: "",
  password: "",
  phone: "",
  captcha: "",
});

const registerFormRef = ref(null);

// 注册显示加载中
const registerLoading = ref(false);

// 验证码 请求状态
// const codeRequest = ref(false);

// 验证码图片网址
// const captchaImage = ref(
//   `${config.apiBaseUrl}/${config.version}/customer/captcha`
// );

/**
 * 检查用户名称的模式（4个号码/字母）
 * @param rule
 * @param value
 * @param callback
 */
const validateUsername = (rule, value, callback) => {
  const pattern = /^[0-9A-Za-z]{4,}$/;
  if (!value) {
    callback(new Error(`${t("pleaseEnterUsername")}`));
  } else if (!pattern.test(value)) {
    callback(new Error(`${t("username4Characters")}`));
  } else {
    callback();
  }
};

/**
 * 检查密码的模式（8个号码/字母以上）
 * @param rule
 * @param value
 * @param callback
 */
const validatePassword = (rule, value, callback) => {
  const pattern = /^(?=.*[a-zA-Z])(?=.*\d)[0-9A-Za-z]{8,}$/;
  if (!value) {
    callback(new Error(`${t("pleaseEnterPassword")}`));
  } else if (!pattern.test(value)) {
    callback(new Error(`${t("password8Alphabetical")}`));
  } else {
    callback();
  }
};

/**
 * 检查手机号码的模式（只有号码，且在9~11个号码之间）
 * @param rule
 * @param value
 * @param callback
 */
const validatePhoneNumber = (rule, value, callback) => {
  if (!phoneNumber.value) {
    callback(new Error(`${t("pleaseEnterPhoneNumber")}`));
  } else if (!results.value.isValid) {
    callback(new Error(`${t("phoneCorrectFormat")}`));
  } else {
    callback();
  }
};

/**
 * 检查验证码的模式（六个号码/字母）
 * @param rule
 * @param value
 * @param callback
 */
// const validateVerificationCode = (rule, value, callback) => {
//   const pattern = /^[0-9A-Za-z]{6}$/;
//   if (!value) {
//     callback(new Error("Please input the verification code"));
//   } else if (!pattern.test(value)) {
//     callback(new Error("Verification code must be 6 alphanumeric characters"));
//   } else {
//     callback();
//   }
// };

/**
 * 检查验证码是否为空
 * @param rule
 * @param value
 * @param callback
 */
// const validateCaptcha = (rule, value, callback) => {
//   if (!value) {
//     callback(new Error("Please enter the correct captcha"));
//   } else {
//     callback();
//   }
// };

/**
 * 验证注册规则
 */
const rules = {
  user: [
    { message: "Please enter a username", trigger: "blur" },
    { validator: validateUsername, trigger: "blur" },
  ],
  password: [
    { message: "Please enter a password", trigger: "blur" },
    { validator: validatePassword, trigger: "blur" },
  ],
  phone: [
    { message: "Please enter a phone number", trigger: "blur" },
    { validator: validatePhoneNumber, trigger: "blur" },
  ],
  // code: [
  //   {
  //     message: "Please enter verification code",
  //     trigger: "blur",
  //   },
  //   { validator: validateVerificationCode, trigger: "blur" },
  // ],
  // captcha: [
  //   { message: "Please enter captcha", trigger: "blur" },
  //   { validator: validateCaptcha, trigger: "blur" },
  // ],
};

/**
 * 刷新验证码图片
 */
// const reloadCaptcha = () => {
//   captchaImage.value = `${config.apiBaseUrl}/${
//     config.version
//   }/customer/captcha?id=${Math.random()}`;
// };

// onMounted(() => {
//   reloadCaptcha();
// });

const submitForm = async () => {
  if (!registerFormRef.value) return;

  // 检测输入是否合格
  registerFormRef.value.validate(async (valid) => {
    if (valid) {
      // 合格
      await axios
        .post(`${config.apiBaseUrl}/${config.version}/customer/register`, {
          username: form.user,
          password: form.password,
          phone: phoneNumber.value,
          captcha: form.captcha,
        })
        .then(() => {
          window.alert("Register Success");
        })
        .catch((error) => {
          // 发生错误
          console.error("Registeration Error:", error);

          let errorMessage = error.response.data.data;
          let message = `${t("registerFail")}`;
          let firstErrorMessage = errorMessage[Object.keys(errorMessage)[0]];

          if (firstErrorMessage) {
            message += `: ${firstErrorMessage}`;
          }
          window.alert(message);
        })
        .finally(() => {
          registerLoading.value = false;
        });
    } else {
      // 不合格
      registerLoading.value = false;
    }
  });
};
</script>
<style scoped>
.form {
  width: 75%;
  height: 100%;
  align-items: center;
  margin: 0 auto;
}
/* Form */
.form::v-deep .el-form-item {
  margin-bottom: 10px;
}

/* Input Box */
.input-box {
  flex-direction: column !important;
}

.input-box::v-deep .el-form-item__label {
  justify-content: flex-start;
}

.code-button {
  color: white !important;
  background-color: #409eff !important;
}
/* Phone Input Box */
.phone-input-field {
  width: 100%;
}

.phone-input-field::v-deep .m-input-label,
.phone-input-field::v-deep .m-input-input {
  font-size: 14px !important;
}

.phone-input-field::v-deep .m-country-selector .m-input-wrapper {
  border-radius: 6px 6px 0 0 !important;
}

.phone-input-field::v-deep .m-phone-input .m-input-wrapper {
  border-radius: 0 0 6px 6px !important;
}

/* Button List */
.button-list {
  margin-top: 10px;
}

.button-list::v-deep .el-form-item__content {
  flex-direction: column;
}

.button-list::v-deep .button {
  width: 100%;
  margin: 10px 0 10px 0;
}

.fa-spinner {
  margin-left: 10px;
  color: #828282;
  animation: rotate 1s linear infinite;
}

.captcha-input {
  width: 100%;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
}
.captcha-input::v-deep .el-input {
  width: 83%;
}
</style>
