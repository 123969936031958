<template lang="">
  <div class="booking">
    <h3>{{ $t("howToBookTour") }}</h3>
    <ul>
      <li>
        {{ $t("contactStaff") }}
      </li>
      <li>
        {{ $t("invoiceNotification") }}
      </li>
      <li>
        {{ $t("sendProofOfDeposit") }}
      </li>
      <li>
        {{ $t("bookingVisa") }}
      </li>
      <li>
        {{ $t("payRemainingAmount") }}
      </li>
      <li>
        {{ $t("sendTravelAppointment") }}
      </li>
    </ul>
    <img class="tutorial-image" src="../assets/payment_tutorial.jpg" />
    <h3>{{ $t("payment") }}</h3>
    <ul>
      <li class="payment-info">
        {{ $t("paymentMethods") }}:
        <h4>1. {{ $t("bankTransfer") }}</h4>
        <table class="payment-table">
          <thead>
            <tr>
              <th>{{ $t("bank") }}</th>
              <th>{{ $t("accountType") }}</th>
              <th>{{ $t("branch") }}</th>
              <th>{{ $t("accountNumber") }}</th>
            </tr>
          </thead>
        </table>
        <ul style="margin-top: 15px">
          <a
            style="
              margin-left: -20px;
              font-size: 1.1em;
              text-decoration: underline;
            "
            >{{ $t("bank_transfer") }}</a
          >
          <li>
            {{ $t("bank_counter") }}
          </li>
          <li>
            {{ $t("atm_transfer") }}
          </li>
          <li>
            {{ $t("auto_deposit_machine") }}
          </li>
          <li>
            {{ $t("internet_banking") }}
          </li>
        </ul>
        <h4 style="margin-bottom: 0">2. {{ $t("pay_via_credit_card") }}</h4>
        <a style="padding-left: 25px">{{ $t("credit_card_payment") }}</a>
      </li>
    </ul>
    <h3>{{ $t("how_to_notify_payment") }}</h3>
    <a>{{ $t("notify_payment_instructions") }}</a>
    <ul>
      <h5 style="font-size: 1em">1. {{ $t("telephone_notification") }}</h5>
      <li style="margin-left: 35px">{{ $t("date_of_payment") }}</li>
      <li style="margin-left: 35px">{{ $t("bank_name") }}</li>
      <li style="margin-left: 35px">{{ $t("amount_paid") }}</li>
    </ul>

    <ul>
      <h5 style="font-size: 1em">2. {{ $t("line_notification") }}</h5>
      <li style="margin-left: 35px">{{ $t("date_of_payment") }}</li>
      <li style="margin-left: 35px">
        {{ $t("bank_name") }}
      </li>
      <li style="margin-left: 35px">{{ $t("amount_paid") }}</li>
    </ul>
  </div>
</template>
<script setup></script>
<style scoped>
/* Booking Tutorial */
.booking {
  padding: 20px;
}

.booking h3 {
  color: #000166;
  font-weight: bold;
}

.booking ul {
  list-style-type: square;
  padding-left: 5%;
}

.booking li {
  font-size: 0.95em;
  line-height: 35px;
}

.booking .tutorial-image {
  width: 100%;
}

.booking table {
  border-collapse: collapse;
}

.booking tr {
  background: #f2f2f2;
}

.booking th {
  padding: 5px 25px;
  border: 1px solid #ddd;

  @media (max-width: 991px) {
    padding: 0 5px;
    font-size: 0.8em;
  }
}
</style>
